import React, { useRef, useEffect } from "react";
import ServicesHero from "../../ServicesHero";
import englishMathTutoring from "../../assets/englishMathTutoringIllustration.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useState } from "react";
import EnglishTutoring from "../../lowerLevelTutoring/EnglishTutoring";
import MathTutoring from "../../lowerLevelTutoring/MathTutoring";
import { fadeLeftToRight, fadeUp } from "../..";
import privateTutoringIcon from "../../assets/ServicesIcons/private-tutoring-icon.png";
import groupTutoringIcon from "../../assets/ServicesIcons/group-tutoring-icon.png";
import { motion, useScroll, useTransform } from "framer-motion";
import OnlineLearning from "../../OnlineLearning";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import PrivateGroupTutor from "../../PrivateGroupTutor";

import yellowDottedPath from "../../assets/vectors/yellowDottedPath.svg";
import orangeDottedPath from "../../assets/vectors/orangeDottedPath.svg";

const LowerLevelTutoring = () => {
  const sectionRef = useRef();
  const triggerRef = useRef();
  const infoRef = useRef();
  const wrapperRef = useRef();
  const elementRef = useRef();

  // const [displaySection, setDisplaySection] = useState({
  //   english: true,
  //   math: false,
  // });
  gsap.registerPlugin(ScrollTrigger);
  const [timeOut, setTimeOut] = useState(false);
  const setTime = () => {
    const timeOutFunction = () => {
      ScrollTrigger.refresh();
      setTimeOut(true);
    };

    setTimeout(timeOutFunction, 1000);
  };

  useEffect(() => {
    // let ctx = gsap.context(() => {
    setTime();
    gsap.fromTo(
      infoRef.current,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: infoRef.current,
          start: "20% center",
          end: "bottom center",
          scrub: 2,
          // pin: true,
          // markers: true,
        },
      }
    );

    ScrollTrigger.refresh();

    gsap.fromTo(
      wrapperRef.current,
      {
        translateX: 0,
      },
      {
        translateX: "-1200",
        ease: "none",
        duration: 1,
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: "top center",
          end: "bottom center",
          scrub: 2,
          // pin: true,
          // markers: true,
        },
      }
    );

    // ScrollTrigger.refresh();
    // }, sectionRef);

    // return () => ctx.cleaup(); // cleanup
  }, [timeOut]);

  // const headingRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    // target: headingRef,
    // offset: ["start center", "end end"],
  });

  const xEnglish = useTransform(scrollYProgress, [0, 1], [-100, -1000]);
  const xMath = useTransform(scrollYProgress, [0, 1], [-1000, -100]);
  const xOffer = useTransform(scrollYProgress, [0, 1], [500, -500]);

  // const displayEnglish = () => {
  //   setDisplaySection({ english: true, math: false });
  // };

  // const displayMath = () => {
  //   setDisplaySection({ english: false, math: true });
  // };

  const threePhasedApproach = [
    {
      number: "Phase 01",
      heading: "Modelled instruction",
      information:
        "We present, demonstrate and break down skills into clear and easy-to-follow steps for our students.",
    },
    {
      number: "Phase 02",
      heading: "Guided practice",
      information:
        "Students practise the taught skills alongside with us, receiving on-the-spot and corrective feedback along the way.",
    },
    {
      number: "Phase 03",
      heading: "Independent Practice",
      information:
        "We check for understanding and mastery by having our students practise the taught skills independently.",
    },
  ];

  const privateTutor = {
    heading: "Private Tutoring",
    body: "Highly individualised one-to-one tutoring enables targeted instruction where every session is tailored to your child’s very own individual learning needs and goals. This includes but not limited to:",
    extraBody: [
      "Bridging learning gaps",
      "Revision",
      "Extending knowledge",
      "Homework assistance",
    ],
    className: "xl:h-[40rem] md:translate-y-10 ",
  };

  const groupTutor = {
    heading: "Small Group Tutoring",
    body: "Small group tutoring offers a more budget-friendly option where your child will experience the perfect blend of collaborative learning and individualised attention. Our classes are carefully sized to still allow for targeted instruction, guidance and support.",
    className: "xl:h-[33rem]",
  };

  return (
    <section
      ref={sectionRef}
      className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Tutoring"
        secondHeading="Years 3 to 10 English and Mathematics Tutoring"
        headingDescription="We are specialists in addressing the top concern held by an increasing number of parents like yourself: students are progressing through school without a solid grasp of the fundamental basic skills in English and Mathematics. This has been exacerbated by the disruption to learning brought about by COVID-19. Consequently, a large proportion of students have fallen behind and are not performing at the expected level. Your child may be one of them."
        leftTag="English Tutoring"
        rightTag="Mathematics Tutoring"
        image={englishMathTutoring}
        imageDescription="Illustration of scholarship selective school exam"
      />
      <div className="w-full">
        <h2 className="text-gray-500 text-3xl font-bold mt-20 text-center w-[70%] mx-auto">
          Like many parents, we also believe in the importance of laying strong
          foundations in English and Mathematics for our students with an
          intensive focus on reading, writing and arithmetic (the operation of
          numbers).
        </h2>
        {/* Container */}
        <div
          ref={triggerRef}
          className="my-48 scroll-section-outer h-[30rem] md:h-96 xl:h-72 relative border-[3px] border-purple rounded-l-none rounded-r-3xl  sm:mx-5 md:mx-10 overflow-hidden">
          {/* Left side */}
          <div
            ref={infoRef}
            className="absolute left-0 w-[10rem] h-[29.8rem] sm:w-[15rem] md:w-96 md:h-96 xl:h-72 md:text-lg bg-purple rounded-r-3xl sm:rounded-r-full flex justify-center items-center px-5 md:px-10 border-4 border-yellow z-50">
            <p className="text-white font-semibold">
              To achieve this, we centre our lesson delivery with explicit
              teaching, a highly-structured, three-phased approach to teaching
              and learning.
            </p>
          </div>
          {/* END - Left side */}
          {/* Right Side */}
          <div
            ref={wrapperRef}
            className="scroll-section-inner flex my-auto h-full w-[200%] md:w-[150%] ml-[25rem] gap-5">
            {threePhasedApproach.map((approach, index) => (
              <div
                ref={elementRef}
                key={`Approach_${index}`}
                className="scroll-section bg-yellow w-full border-4 border-purple my-4 p-2 rounded-3xl">
                <h2 className="text-8xl text-purple">{approach.number}</h2>
                <h3 className="text-3xl text-blue font-bold mt-5">
                  {approach.heading}
                </h3>
                <p className="text-blue mt-2">{approach.information}</p>
              </div>
            ))}
          </div>
          {/* End - Right Side */}
        </div>
        {/* End - Container */}
        <h2 className="text-gray-500 text-3xl font-bold mt-20 text-center w-[70%] mx-auto">
          Extensive educational research conducted in Australia and
          internationally confirms explicit teaching as the most effective
          approach to building strong foundational subject knowledge, skills,
          and understanding.
        </h2>
        {/* <div id="subject" className="flex flex-col justify-center items-center">
          <img
            className="w-72 h-72"
            src={englishMathArrows}
            alt="English and Math arrows pointing down at the buttons, showing pick one text above the arrows"
          />
          <div className="flex justify-center gap-5 sm:gap-20 text-white ">
            <button
              className="bg-purple p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-purple my-2 hover:bg-yellow hover:ring-yellow"
              onClick={displayEnglish}>
              English
            </button>
            <button
              className="bg-blue p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-blue my-2 hover:bg-yellow hover:ring-yellow"
              onClick={displayMath}>
              Mathematics
            </button>
          </div>
        </div> */}
        {/* ENGLISH MATH SECTIONS */}
        <motion.h1
          style={{ x: xEnglish }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap mt-48">
          English
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            English •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            English •
          </motion.span>
        </motion.h1>
        <EnglishTutoring />
        <motion.h1
          style={{ x: xMath }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap mt-48">
          Mathematics
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Mathematics •
          </motion.span>
        </motion.h1>
        <MathTutoring />
        {/* {displaySection.english ? <EnglishTutoring /> : null}
        {displaySection.math ? <MathTutoring /> : null}
        {displaySection.english ? (
          <div className="flex justify-center gap-5 sm:gap-20 text-white mt-10">
            <Link to={"subject"} smooth={true} className="text-base text-white">
              <button
                className="bg-blue p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-blue my-2 hover:bg-yellow hover:ring-yellow"
                onClick={displayMath}>
                View Mathematics
              </button>
            </Link>
          </div>
        ) : (
          <div className="flex justify-center gap-5 sm:gap-20 text-white mt-10">
            <Link
              to={"subject"}
              smooth={true}
              className="text-base text-white ">
              <button
                className="bg-purple p-5 rounded-full w-40 sm:w-48 ring-2 ring-offset-2 ring-purple my-2 hover:bg-yellow hover:ring-yellow"
                onClick={displayEnglish}>
                View English
              </button>
            </Link>
          </div>
        )} */}
        {/* END - ENGLISH MATH SECTIONS */}
      </div>
      {/* Private Group tutoring */}
      <img className="mt-20 max-w-[150%]" src={yellowDottedPath} />
      <div
        // ref={headingRef}
        className="flex flex-col justify-center items-center mt-48">
        <motion.h1
          style={{ x: xOffer }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          What we offer?
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            What we offer?
          </motion.span>
        </motion.h1>
        {/* <motion.h3
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-lg font-semibold tracking-wider text-purple uppercase">
          Tutoring types
        </motion.h3>
        <motion.h2
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className=" text-5xl font-bold text-blue ">
          What we offer?
        </motion.h2> */}
        {/* <div className="flex flex-col md:flex-row gap-20 w-[90%] mt-20"> */}
      </div>
      <PrivateGroupTutor privateTutor={privateTutor} groupTutor={groupTutor} />
      {/* </div> */}
      <img className="mt-40 max-w-[150%]" src={orangeDottedPath} />
      {/* End - Private group tutoring */}

      <OnlineLearning customText={"your child"} />
    </section>
  );
};

export default LowerLevelTutoring;
