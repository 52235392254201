import React from "react";
import { motion } from "framer-motion";
import privateTutoringIcon from "./assets/ServicesIcons/private-tutoring-icon.png";
import groupTutoringIcon from "./assets/ServicesIcons/group-tutoring-icon.png";
import { fadeLeftToRight, fadeUp } from ".";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

const PrivateGroupTutor = ({ privateTutor, groupTutor }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 w-[90%] xl:w-[80%]">
      {/* Private Tutoring */}
      <div
        className={`md:flex-row mt-28 bg-cta rounded-3xl ${privateTutor?.className}`}>
        <div className="flex flex-col border-[2px] border-yellow rounded-3xl px-10 py-5 gap-3 h-full w-full -translate-x-2 -translate-y-1">
          {/* Left side */}
          <div className="mt-5">
            <img
              //   variants={fadeLeftToRight}
              //   initial={"hidden"}
              //   whileInView={fadeLeftToRight.visible}
              //   transition={{ duration: 1 }}
              //   viewport={{ once: true }}
              // className="mx-auto md:m-20 w-[30%] h-[30%]"
              src={privateTutoringIcon}
            />
          </div>
          {/* Right side */}
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col gap-5">
              <h1
                // variants={fadeUp}
                // initial={"hidden"}
                // whileInView={fadeUp.visible}
                // transition={{ duration: 1, delay: 0.3 }}
                // viewport={{ once: true }}
                className="text-5xl font-bold text-blue">
                {privateTutor?.heading}
              </h1>
              <motion.p
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="text-xl text-gray-500">
                {privateTutor?.body}
              </motion.p>
              <motion.ul
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="text-lg text-gray-500 list-disc flex flex-col gap-2 pl-10">
                {privateTutor?.extraBody?.map((text) => (
                  <li key={text}>{text}</li>
                ))}
              </motion.ul>
              <motion.div
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="flex">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfY3ByOMjButSfE_s9u2uVJsmpsqTkxPbuFK22o62cMavtgIw/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer">
                  <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                    Enquire now
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                  </button>
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {/* End - Private tutoring */}
      {/* Small Group Tutoring */}
      <div
        className={`md:flex-row md:mt-28 bg-cta rounded-3xl ${groupTutor?.className} `}>
        <div className="flex flex-col border-[2px] border-orange rounded-3xl px-10 py-5 gap-3 h-full w-full -translate-x-2 -translate-y-1 ">
          {/* Left side */}
          <div className="mt-5">
            <img
              //   variants={fadeLeftToRight}
              //   initial={"hidden"}
              //   whileInView={fadeLeftToRight.visible}
              //   transition={{ duration: 1 }}
              //   viewport={{ once: true }}
              // className="mx-auto md:m-20 w-[30%] h-[30%]"
              src={groupTutoringIcon}
            />
          </div>
          {/* Right side */}
          <div className="flex flex-col justify-center items-center">
            <div className="flex flex-col gap-5">
              <h1
                // variants={fadeUp}
                // initial={"hidden"}
                // whileInView={fadeUp.visible}
                // transition={{ duration: 1, delay: 0.3 }}
                // viewport={{ once: true }}
                className="text-5xl font-bold text-blue">
                {groupTutor?.heading}
              </h1>
              <motion.p
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="text-xl text-gray-500">
                {groupTutor?.body}
              </motion.p>
              <motion.div
                variants={fadeUp}
                initial={"hidden"}
                whileInView={fadeUp.visible}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="flex">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfY3ByOMjButSfE_s9u2uVJsmpsqTkxPbuFK22o62cMavtgIw/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer">
                  <button className="flex w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                    Enquire now
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
                  </button>
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {/* End - Small Group Tutoring */}
    </div>
  );
};

export default PrivateGroupTutor;
