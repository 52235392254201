import React from "react";

const ExamMarkingService = ({ subject }) => {
  const vceSubjects = [
    {
      name: "English",
    },
    {
      name: "English Language",
    },
    {
      name: "English Literature",
    },
  ];

  const hscSubjects = [
    {
      name: "English - Standard",
    },
    {
      name: "English - Advanced",
    },
  ];

  const qceSubjects = [
    {
      name: "English",
    },
  ];

  return (
    <section className="md:mb-52">
      {/* SUBJECTS */}
      <div className="relative flex flex-col gap-2 h-[17rem] items-center bg-purple rounded-3xl">
        <div className="flex flex-col border-[2px] border-yellow rounded-3xl justify-start py-10 items-center gap-3 h-full w-full -translate-x-2 -translate-y-1">
          {/* <div className="ring ring-blue ring-offset-1 rounded-full mx-14 p-2"> */}
          <h2 className="text-yellow font-bold text-xl text-center mb-5 mx-10">
            {subject === "VCE"
              ? "Victorian Certificate of Education (VCE)"
              : subject === "HSC"
              ? "New South Wales High School Certificate (HSC)"
              : subject === "QCE"
              ? "Queensland Certificate of Education (QCE)"
              : null}
          </h2>
          {/* </div> */}
          {subject === "VCE" &&
            vceSubjects.map((subject, index) => (
              <p key={`vceSubject_exam_${index}`} className="text-white">
                {subject.name}
              </p>
            ))}
          {subject === "HSC" &&
            hscSubjects.map((subject, index) => (
              <p key={`hscSubject_exam_${index}`} className="text-white">
                {subject.name}
              </p>
            ))}
          {subject === "QCE" &&
            qceSubjects.map((subject, index) => (
              <p key={`qceSubject_exam_${index}`} className="text-white">
                {subject.name}
              </p>
            ))}
        </div>
      </div>
      {/* END SUBJECTS */}
    </section>
  );
};

// {/* <div
//           ref={subjectRef}
//           className="relative flex flex-col justify-center items-center w-full text-center">
//           <img
//             className="w-full lg:w-[80%] mt-20 z-10"
//             src={cloud}
//             alt="a cloud shape"
//           />
//           <p className="absolute top-[27%] lg:top-[30%] text-white font-bold text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl z-[11]">
//             Subjects
//           </p>
//           <div className="grid grid-cols-3 translate-x-5 lg:translate-x-10 w-full">
//             {/* Subject 1 */}
//             <motion.div className="relative" style={{ y: y2, opacity }}>
//               <svg
//                 className={`-translate-y-40 lg:-translate-y-32 w-[80%]`}
//                 width="249"
//                 height="277"
//                 viewBox="0 0 249 277"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg">
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
//                   fill="#863D90"
//                 />
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
//                   stroke="#E6E7E8"
//                   strokeWidth="3"
//                 />
//               </svg>
//               <p className="absolute left-6 -top-14 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.8rem] sm:text-lg md:text-xl text-white lg:text-2xl font-semibold w-[40%] sm:w-[50%]">
//                 English Units 1-4
//               </p>
//             </motion.div>
//             {/* END - subject 1 */}
//             {/* Subject 2 */}
//             <motion.div className="relative" style={{ y: y1, opacity }}>
//               <svg
//                 className={`-translate-y-40 lg:-translate-y-32 w-[80%]`}
//                 width="249"
//                 height="277"
//                 viewBox="0 0 249 277"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg">
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
//                   fill="#863D90"
//                 />
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
//                   stroke="#E6E7E8"
//                   strokeWidth="3"
//                 />
//               </svg>
//               <p className="absolute left-6 -top-14 sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.7rem] sm:text-lg md:text-xl text-white lg:text-2xl font-semibold w-[40%] sm:w-[50%]">
//                 English Language Units 1-4
//               </p>
//             </motion.div>
//             {/* END - subject 2 */}

//             {/* Subject 3 */}
//             <motion.div className="relative" style={{ y: y2, opacity }}>
//               <svg
//                 className={`-translate-y-40 lg:-translate-y-32 w-[80%]`}
//                 width="249"
//                 height="277"
//                 viewBox="0 0 249 277"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg">
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M126.02 0.587734C166.256 -2.16628 211.614 3.93382 235.226 36.629C258.103 68.3073 242.297 110.192 232.489 148.016C223.217 183.773 214.389 222.29 182.327 240.636C147.943 260.311 104.824 259.023 69.3671 241.355C34.4688 223.965 10.796 189.973 3.71073 151.632C-3.02481 115.183 10.753 79.2029 34.0791 50.3968C57.1564 21.8978 89.4343 3.0919 126.02 0.587734Z"
//                   fill="#863D90"
//                 />
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M126.02 20.5877C166.256 17.8337 211.614 23.9338 235.226 56.629C258.103 88.3073 242.297 130.192 232.489 168.016C223.217 203.773 214.389 242.29 182.327 260.636C147.943 280.311 104.824 279.023 69.3671 261.355C34.4688 243.965 10.796 209.973 3.71073 171.632C-3.02481 135.183 10.753 99.2029 34.0791 70.3968C57.1564 41.8978 89.4343 23.0919 126.02 20.5877Z"
//                   stroke="#E6E7E8"
//                   strokeWidth="3"
//                 />
//               </svg>
//               <p className="absolute left-6 -top-[3.6rem] sm:-top-[3.5rem] sm:left-7 lg:left-[15%] lg:-top-[15%] text-[0.7rem] sm:text-lg md:text-xl text-white lg:text-2xl font-semibold w-[40%] sm:w-[50%]">
//                 English Literature Units 1-4
//               </p>
//             </motion.div>
//             {/* END - subject 3 */}
//           </div>
//         </div> */}

export default ExamMarkingService;
