import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import Logo from "./assets/white-logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Transition, Popover } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  ChatBubbleLeftRightIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  UsersIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  FaceSmileIcon,
  UserCircleIcon,
  RocketLaunchIcon,
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

// const aboutLocal = [
//   {
//     name: "About TutorWo",
//     description: "Get a better understanding of who we are and what we do",
//     href: "about",
//     icon: InformationCircleIcon,
//   },
//   {
//     name: "The Founder - Julian Jom",
//     description: "Read more about the founder of TutorWo",
//     href: "julian",
//     icon: FaceSmileIcon,
//   },
//   {
//     name: "Why TutorWo?",
//     description: "Many reasons why you should choose TutorWo",
//     href: "whyus",
//     icon: CheckCircleIcon,
//   },
// ];

// const aboutRoute = [
//   {
//     name: "Meet our tutors",
//     description: "Get to know our high quality tutors",
//     href: "/our-tutors",
//     icon: UserCircleIcon,
//   },
//   {
//     name: "FAQ",
//     description: "Your question might be here",
//     href: "/faq",
//     icon: QuestionMarkCircleIcon,
//   },
// ];

const services = [
  {
    name: "Years 3 to 10 English and Mathematics Tutoring",
    description: `
    Bridge gaps, build strong foundational skills, and get ahead in reading, writing, and mathematics`,
    href: "/english-math-tutoring",
    icon: UsersIcon,
  },
  // {
  //   name: "Scholarship and Selective Entry School Examinations Preparation",
  //   description: `Be guided and mentored by experts who themselves have aced the exams, been awarded scholarships, and gained entry into selective entry schools`,
  //   href: "/scholarship-selective-school",
  //   icon: RocketLaunchIcon,
  // },
  {
    name: "VCE / HSC / QCE Tutoring",
    description: `Unlock unrivalled access to experts who will share their secrets to achieving VCE / HSC / QCE success`,
    href: "/vce-hsc-qce-tutoring",
    icon: UsersIcon,
  },
  {
    name: "VCE / HSC / QCE Essay and Examination Marking Service",
    description: `Perfect your essays and exams with personalised, comprehensive, and highly targeted feedback.`,
    href: "/vce-hsc-qce-essay-exam-marking",
    icon: PencilSquareIcon,
  },
  {
    name: "VCE / HSC / QCE Study Guides",
    description: `Get your hands on notes and summaries written by top ATAR scorers`,
    href: "/vce-hsc-qce-study-guides",
    icon: DocumentTextIcon,
  },
  // {
  //   name: "Master Classes",
  //   description:
  //     "VCE head start programs, intensive workshops, examination preparation and revision",
  //   href: "/master-classes",
  //   icon: PresentationChartLineIcon,
  // },
  // {
  //   name: "Private Tutoring",
  //   description: "More about our private tutoring",
  //   href: "/private-tutoring",
  //   icon: UsersIcon,
  // },
];

const findTutor = {
  name: "Enquire Now",
  description: "Enquire now to find a suitable tutor",
  href: "https://docs.google.com/forms/d/e/1FAIpQLSfY3ByOMjButSfE_s9u2uVJsmpsqTkxPbuFK22o62cMavtgIw/viewform?usp=sf_link",
  icon: UserGroupIcon,
};

const contact = [
  {
    name: "Become a tutor",
    description: "Interested to work with us?",
    href: "/work-with-us",
    icon: UserIcon,
  },
  {
    name: "General enquiry",
    description: "Have a question in mind?",
    href: "/contact",
    icon: ChatBubbleLeftRightIcon,
  },
];

const NavBar = () => {
  let location = useLocation();

  return (
    <header className="sticky top-0 z-[100]">
      <Popover className="relative bg-purple z-50">
        <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-3">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/">
              <span className="sr-only">TutorWo</span>
              <img className="h-7 w-auto lg:h-8 xl:h-10" src={Logo} alt="" />
            </NavLink>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-purple ring-2 ring-white rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-yellow">
              <span className="sr-only">Open menu</span>
              <Bars3Icon
                className="h-6 w-6 stroke-white stroke-2"
                aria-hidden="true"
              />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <NavLink
              exact="true"
              to="/our-tutors"
              className="text-md lg:text-lg font-medium text-white hover:text-yellow py-2 mx-5">
              Meet our team
            </NavLink>
            {/* <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-yellow" : "text-white",
                      "group bg-purple rounded-md inline-flex items-center text-md lg:text-lg font-medium hover:text-yellow focus:outline-none py-2"
                    )}>
                    <span>About</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-yellow" : "text-white",
                        "ml-2 h-5 w-5 group-hover:text-yellow stroke-white"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {aboutLocal.map((item) =>
                            location.pathname === "/" ? (
                              <Link
                                key={item.name}
                                to={item.href}
                                smooth={true}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-yellow cursor-pointer">
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-purple sm:h-12 sm:w-12">
                                  <item.icon
                                    className="h-6 w-6 stroke-purple stroke-2"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-blue">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </Link>
                            ) : (
                              <NavLink
                                key={item.name}
                                exact
                                to={"/#" + item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-yellow cursor-pointer">
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-purple sm:h-12 sm:w-12">
                                  <item.icon
                                    className="h-6 w-6 stroke-purple stroke-2"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-blue">
                                    {item.name}
                                  </p>
                                  <p className="mt-1 text-sm text-gray-500">
                                    {item.description}
                                  </p>
                                </div>
                              </NavLink>
                            )
                          )}
                          {aboutRoute.map((item) => (
                            <NavLink
                              key={item.name}
                              exact
                              to={item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-yellow">
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-purple sm:h-12 sm:w-12">
                                <item.icon
                                  className="h-6 w-6 stroke-purple stroke-2"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4">
                                <p className="text-base font-medium text-blue">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover> */}
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-yellow" : "text-white",
                      "group bg-purple rounded-md inline-flex items-center text-md lg:text-lg font-medium hover:text-yellow focus:outline-none py-2"
                    )}>
                    <span>What we offer</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-yellow" : "text-white",
                        "ml-2 h-5 w-5 group-hover:text-yellow stroke-white"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {services.map((item) => (
                            <NavLink
                              key={item.name}
                              exact="true"
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow transition ease-in-out duration-150">
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-purple stroke-purple stroke-2"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-blue">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-yellow" : "text-white",
                      "group bg-purple rounded-md inline-flex items-center text-md lg:text-lg font-medium hover:text-yellow focus:outline-none py-2"
                    )}>
                    <span>Contact us</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-yellow" : "text-white",
                        "ml-2 h-5 w-5 group-hover:text-yellow stroke-white"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1">
                    <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          <a
                            href={findTutor.href}
                            target="_blank"
                            rel="noreferrer"
                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow transition ease-in-out duration-150">
                            <findTutor.icon
                              className="flex-shrink-0 h-6 w-6 text-purple stroke-purple stroke-2"
                              aria-hidden="true"
                            />
                            <div className="ml-4">
                              <p className="text-base font-medium text-blue flex">
                                {findTutor.name}
                                <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2 stroke-blue stroke-2" />
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                {findTutor.description}
                              </p>
                            </div>
                          </a>
                          {contact.map((item) => (
                            <NavLink
                              key={item.name}
                              exact="true"
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow transition ease-in-out duration-150">
                              <item.icon
                                className="flex-shrink-0 h-6 w-6 text-purple stroke-purple stroke-2"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-blue">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500">
                                  {item.description}
                                </p>
                              </div>
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfY3ByOMjButSfE_s9u2uVJsmpsqTkxPbuFK22o62cMavtgIw/viewform?usp=sf_link"
              target="_blank"
              className="text-md lg:text-lg font-medium text-purple hover:bg-yellow rounded-xl bg-white px-3 py-2 flex items-center">
              Enquire Now
              <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2 stroke-purple stroke-2" />
            </a>
            <div className="hidden lg:flex items-center">
              <a href="https://www.instagram.com/tutorwo_/" target="_blank">
                <InstagramIcon
                  className="text-white mx-2 hover:text-yellow"
                  sx={{ fontSize: "1.8rem" }}
                />
              </a>
              <a href="https://www.facebook.com/TutorWo" target="_blank">
                <FacebookIcon
                  className="text-white  hover:text-yellow"
                  sx={{ fontSize: "1.8rem" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/tutorwo/"
                target="_blank">
                <LinkedInIcon
                  className="text-white mx-2 hover:text-yellow"
                  sx={{ fontSize: "1.8rem" }}
                />
              </a>
            </div>
          </Popover.Group>
        </div>
        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden ">
            <div className="rounded-lg shadow-xl bg-purple ring-2 ring-white">
              <div className="pt-5 px-5">
                <div className="flex items-center justify-between z-50 ">
                  <div>
                    <img className="h-8 w-auto z-50" src={Logo} alt="TutorWo" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-purple ring-2 ring-white rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-yellow">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon
                        className="h-6 w-6 stroke-white stroke-2"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              {/* Wave shape */}
              <svg
                className="relative -z-1"
                style={{
                  marginTop: "-1px",
                  marginBottom: "-2px",
                  strokeWidth: 0,
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320">
                <path
                  fill="#FFF"
                  stroke="none"
                  strokeOpacity="0"
                  fillOpacity="1"
                  d="M0,160L80,170.7C160,181,320,203,480,197.3C640,192,800,160,960,144C1120,128,1280,128,1360,128L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
              </svg>
              {/* Mobile navigation */}
              <div className="px-5 pb-5 rounded-b-xl bg-white shadow-xl ring-2 ring-white">
                <nav>
                  <div className="grid grid-cols-1 gap-4 text-purple font-bold text-lg cursor-pointer">
                    <ul>
                      <NavLink to="/">
                        <Popover.Button className="w-[100%] flex items-center">
                          <li className="hover:text-yellow">Home</li>
                        </Popover.Button>
                      </NavLink>
                    </ul>
                    <ul>
                      <NavLink to="/our-tutors">
                        <Popover.Button className="w-[100%] flex items-center">
                          <li className="hover:text-yellow">Meet our team</li>
                        </Popover.Button>
                      </NavLink>
                    </ul>
                    {/* <div className="group">
                      <ul className="flex items-center gap-2">
                        <li className="hover:text-yellow">About</li>
                        <ChevronDownIcon className="h-5 w-5 stroke-purple" />
                      </ul>
                      <div className="mt-5 grid-cols-2 gap-4 hidden group-hover:grid">
                        {aboutLocal.map((item) =>
                          location.pathname === "/" ? (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow ">
                              <Popover.Button className="w-[100%] flex items-center text-left">
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md  text-purple ">
                                  <item.icon
                                    className="h-6 w-6 stroke-purple stroke-2"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4 text-base flex font-medium text-blue">
                                  {item.name}
                                </div>
                              </Popover.Button>
                            </Link>
                          ) : (
                            <NavLink
                              key={item.name}
                              exact
                              to={"/#" + item.href}
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-yellow cursor-pointer">
                              <Popover.Button className="w-[100%] flex items-center text-left">
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-purple sm:h-12 sm:w-12">
                                  <item.icon
                                    className="h-6 w-6 stroke-purple stroke-2"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-blue">
                                    {item.name}
                                  </p>
                                </div>
                              </Popover.Button>
                            </NavLink>
                          )
                        )}

                        {aboutRoute.map((item) => (
                          <NavLink
                            key={item.name}
                            exact
                            to={item.href}
                            className="-m-3 p-3 rounded-lg hover:bg-yellow">
                            <Popover.Button className="w-[100%] flex items-center text-left">
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 text-purple">
                                <item.icon
                                  className="h-6 w-6 stroke-purple stroke-2"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base font-medium text-blue">
                                {item.name}
                              </div>
                            </Popover.Button>
                          </NavLink>
                        ))}
                      </div>
                    </div> */}
                    <div className="group">
                      <ul className="flex items-center gap-2">
                        <li className="hover:text-yellow">What we offer</li>
                        <ChevronDownIcon className="h-5 w-5 stroke-purple" />
                      </ul>
                      <div className="mt-5 grid-cols-2 gap-4 hidden group-hover:grid">
                        {services.map((item) => (
                          <NavLink
                            key={item.name}
                            exact="true"
                            to={item.href}
                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow">
                            <Popover.Button className="w-[100%] flex items-center text-left">
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 text-purple">
                                <item.icon
                                  className="h-6 w-6 stroke-purple stroke-2"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base font-medium text-blue">
                                {item.name}
                              </div>
                            </Popover.Button>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                    <div className="group">
                      <ul className="flex items-center gap-2">
                        <li className="hover:text-yellow">Contact us</li>
                        <ChevronDownIcon className="h-5 w-5 stroke-purple" />
                      </ul>
                      <div className="mt-5 grid-cols-2 gap-4 hidden group-hover:grid">
                        <a
                          href={findTutor.href}
                          target="_blank"
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow">
                          <Popover.Button className="w-[100%] flex items-center text-left">
                            <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 text-purple">
                              <findTutor.icon
                                className="h-6 w-6 stroke-purple stroke-2"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4 text-base font-medium text-blue">
                              {findTutor.name}
                            </div>
                          </Popover.Button>
                        </a>
                        {contact.map((item) => (
                          <NavLink
                            key={item.name}
                            exact="true"
                            to={item.href}
                            className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow">
                            <Popover.Button className="w-[100%] flex items-center text-left">
                              <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 text-purple">
                                <item.icon
                                  className="h-6 w-6 stroke-purple stroke-2"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-4 text-base font-medium text-blue">
                                {item.name}
                              </div>
                            </Popover.Button>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSfY3ByOMjButSfE_s9u2uVJsmpsqTkxPbuFK22o62cMavtgIw/viewform?usp=sf_link"
                      target="_blank"
                      className="text-left text-md lg:text-lg font-medium rounded-xl py-2 flex items-center ">
                      <div className="flex justify-center items-center text-center bg-purple hover:bg-yellow text-white  p-3 rounded-full">
                        Enquire Now
                        <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2 stroke-white stroke-2" />
                      </div>
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};

export default NavBar;
