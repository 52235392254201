import React, { useRef, useEffect, useState } from "react";
import essayMarking from "../../assets/essayMarkingIllustration.png";
// import bonus from "../../assets/bonusTag.svg";
import ServicesHero from "../../ServicesHero";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
// import cloud from "../../assets/vectors/cloud.svg";
import examMarkingProcessCircleBlueTick from "../../assets/examMarkingProcessCircleBlueTick.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import MarkingInvestment from "../../MarkingInvestment";
import { fadeUp } from "../..";
import ExamMarkingService from "../subjects/ExamMarkingSubjects";

const VceEssayMarking = () => {
  const subjectRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    target: subjectRef,
    offset: ["start center", "end end"],
  });

  const x = useTransform(scrollYProgress, [0, 1], [-1500, -1000]);

  // const temporaryY = useSpring(scrollYProgress, {
  //   stiffness: 200,
  //   damping: 10,
  //   restDelta: 0.001,
  // });
  // const y1 = useTransform(temporaryY, [0, 1], [-100, 200]);
  // const y2 = useTransform(temporaryY, [0, 1], [-100, 100]);
  // const opacity = useTransform(scrollYProgress, [0, 1], [0, 1]);

  // Timeline
  const [timeOut, setTimeOut] = useState(false);
  const setTime = () => {
    const timeOutFunction = () => {
      ScrollTrigger.refresh();
      setTimeOut(true);
    };

    setTimeout(timeOutFunction, 1000);
  };
  gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

  const comp = useRef();
  useEffect(() => {
    setTime();
    // let ctx = gsap.context(() => {
    const processTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".processTimeline",
        start: "-80% center",
        end: "700% center",
        scrub: true,
        // pin: true,
        // markers: true,
      },
    });
    processTimeline.fromTo(
      ".processTimeline",
      {
        translateX: 0,
      },
      {
        translateX: "-1600",
        ease: "none",
        duration: 1,
      }
    );

    // const textOpacity = gsap.timeline({
    //   defaults: {
    //     opacity: 1,
    //   },
    //   scrollTrigger: {
    //     trigger: "#timeline",
    //     scrub: 2,
    //     start: "-1000% center",
    //     end: "900% center",
    //     // pin: true,
    //   },
    // });

    // textOpacity
    //   .to(".text01", {}, 2)
    //   .to(".text02", {}, 2.1)
    //   .to(".text03", {}, 2.2)
    //   .to(".text04", {}, 2.3);

    const pathMotion = gsap.timeline({
      scrollTrigger: {
        trigger: ".startingPoint",
        scrub: true,
        start: "-700% center",
        end: "900% center",
        // pin: true,
        // markers: true,
      },
    });
    pathMotion.to(
      "#movingCircle",
      {
        motionPath: {
          path: ".pathLine",
          align: ".pathLine",
          alignOrigin: [0.5, 0.5],
        },
        duration: 20,
      },
      0
    );
    // }, comp); // <- IMPORTANT! Scopes selector text

    // return () => ctx.revert(); // cleanup
  }, [timeOut]);

  return (
    <section
      ref={comp}
      className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Feedback service"
        secondHeading="VCE / HSC / QCE Essay and Examination Marking Service"
        headingDescription="High quality, specific and targeted feedback is one of the cornerstones to achieving academic success."
        leftTag="Personalised, comprehensive feedback"
        rightTag="Winning answers and responses"
        image={essayMarking}
        imageDescription="Illustration of VCE / HSC / QCE Essay and Examination Marking Service"
      />
      {/* Content */}
      <div className="flex flex-col justify-center w-[80%] mt-20">
        <motion.h2
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          // viewport={{ once: true }}
          className="text-3xl text-purple font-bold text-center">
          Our team of top ATAR tutors (who have all been there and done that)
          and high-performing school teachers are here to provide you with what
          you are craving for:{" "}
          <span className="text-yellow">
            personalised, comprehensive and highly-targeted feedback
          </span>{" "}
          so you know exactly what you need to do to produce winning answers and
          responses in upcoming SACs and exams.
        </motion.h2>
        {/* Bonus box */}
        {/* <motion.div
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          // viewport={{ once: true }}
          className="border-2 border-purple rounded-3xl mt-20">
          <div className="relative -top-20">
            <img
              className="w-48 h-32"
              src={bonus}
              alt="a odd shape tag with eyes peeking through it"
            />
            <h3 className="absolute top-[50%] left-12 text-white uppercase font-bold">
              Bonus
            </h3>
          </div>
          <p className="flex justify-center items-center mx-5 text-3xl font-semibold text-blue text-center -translate-y-16">
            We offer a free post-feedback 15-minute Zoom session for you to ask
            questions and seek clarifications about the feedback provided.
          </p>
        </motion.div> */}
        {/* End - Bonus box */}
        {/* Subjects */}
        {/* <motion.h1
          ref={subjectRef}
          style={{ x }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          Subjects we offer
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
        </motion.h1> */}
        {/* <h1 className="mx-2 mt-20 lg:mt-40 text-center text-5xl font-bold text-blue">
          What subjects do we offer?
        </h1> */}
        <motion.h1
          style={{ x }}
          className="md:mb-5 mt-40 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          Subjects We Offer
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects We Offer •
          </motion.span>
        </motion.h1>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mt-20 gap-5 text-center">
          <ExamMarkingService subject="VCE" />
          <ExamMarkingService subject="HSC" />
          <ExamMarkingService subject="QCE" />
        </div>
        {/* End - Subjects */}
        {/* Process */}
        <div className="mt-20 mb-72 md:my-20 lg:mt-10 lg:mb-36">
          <h3 className="text-purple text-3xl font-bold text-center">
            The Process
          </h3>
          <div className="processTimeline relative mt-48 w-[400%]">
            <img
              id="movingCircle"
              className="absolute -top-5 left-0 md:-top-8 md:-left-2 w-16 h-16 md:w-24 md:h-24 z-50"
              src={examMarkingProcessCircleBlueTick}
              alt="a circle with blue tick at the center with text 'exam marking process'"
            />
            <svg
              width="1585"
              height="42"
              viewBox="0 0 1585 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                className="pathLine"
                d="M32.5 21H267H656.5H995.5H1341H1569"
                stroke="#863D90"
                strokeWidth="10"
                strokeLinecap="round"
              />
              <circle
                cx="364"
                cy="21"
                r="19"
                fill="#FCC219"
                stroke="white"
                strokeWidth="4"
              />
              <circle
                cx="765"
                cy="21"
                r="19"
                fill="#FCC219"
                stroke="white"
                strokeWidth="4"
              />
              <circle
                cx="1164"
                cy="21"
                r="19"
                fill="#FCC219"
                stroke="white"
                strokeWidth="4"
              />
              <circle
                cx="1564"
                cy="21"
                r="19"
                fill="#FF914D"
                stroke="white"
                strokeWidth="4"
              />
              <path
                className="startingPoint"
                d="M40 21C40 31.4934 31.4934 40 21 40C10.5066 40 2 31.4934 2 21C2 10.5066 10.5066 2 21 2C31.4934 2 40 10.5066 40 21Z"
                fill="#0B4697"
                stroke="white"
                strokeWidth="4"
              />
            </svg>

            <div className="text01 flex absolute bottom-[200%] left-[12rem] w-[20rem] border-2 border-purple rounded-3xl">
              <h1 className="text-3xl mr-2 bg-yellow rounded-full px-2 text-white">
                01
              </h1>
              <p className="text-gray-500 p-1">
                Email the essay/s to{" "}
                <span className="text-yellow">
                  <a href="mailto:tutorwo.tutoring@gmail.com">
                    tutorwo.tutoring@gmail.com
                  </a>
                </span>
              </p>
            </div>
            <div className="text02 flex absolute top-[200%] left-[38rem] w-[20rem] border-2 border-purple rounded-3xl">
              <h1 className="text-3xl mr-2 bg-orange rounded-full px-2 text-white">
                02
              </h1>
              <p className=" text-gray-500 p-1">
                Essays will be marked within 5 business days from the date of
                acknowledgement of the receipt of email
              </p>
            </div>
            <div className="text03 absolute bottom-[200%] left-[63rem] w-[20rem] border-2 border-purple rounded-3xl">
              <h1 className="text-3xl mr-2 bg-blue rounded-full px-2 text-white">
                03
              </h1>
              <p className=" text-gray-500 text-center p-1">
                Essays with feedback will be sent to the nominated email address
                upon completion of marking
              </p>
            </div>
            <div className="text04 flex absolute top-[200%] left-[90rem] w-[20rem] border-2 border-purple rounded-3xl">
              <h1 className="text-3xl mr-2 bg-purple rounded-full px-2 text-white">
                04
              </h1>
              <p className=" text-gray-500 p-1">
                The marker will arrange a post-marking Zoom session.
              </p>
            </div>
          </div>
          {/* End timeline */}
        </div>
        {/* Investment */}
        <MarkingInvestment />
        {/* END investment */}
        {/* End - process */}
      </div>

      {/* End content */}
    </section>
  );
};

export default VceEssayMarking;
