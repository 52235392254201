import "./App.css";
import { Route, Routes } from "react-router-dom";
import {
  NavBar,
  Home,
  // PrivateTutoring,
  // FindTutor,
  // ChildProtection,
  // Privacy,
  // TermsConditions,
  WorkWithUs,
  Contact,
  Footer,
  Faq,
  OurTutors,
} from "./components";
import MasterClasses from "./components/pages/oldServices/MasterClasses";
import Policies from "./components/pages/compliance/Policies";
import LowerLevelTutoring from "./components/pages/newServices/LowerLevelTutoring";
import ScholarshipSelectiveSchool from "./components/pages/newServices/ScholarshipSelectiveSchool";
import VceTutoring from "./components/pages/newServices/VceTutoring";
import VceEssayExamMarking from "./components/pages/newServices/VceEssayExamMarking";
import VceStudyGuides from "./components/pages/newServices/VceStudyGuides";
import { ArrowSmallUpIcon } from "@heroicons/react/20/solid";
import { animateScroll } from "react-scroll";
import { InstagramEmbed } from "react-social-media-embed";
import { motion } from "framer-motion";
// import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";
// import { MessengerChat } from "react-messenger-chat-plugin";

const App = () => {
  return (
    <div className="App">
      <NavBar />
      <main>
        <Routes>
          {/* Home */}
          <Route exact="true" path="/" element={<Home />} />

          {/* About */}
          <Route exact="true" path="/our-tutors" element={<OurTutors />} />
          <Route exact="true" path="/faq" element={<Faq />} />

          {/* Services */}
          <Route
            exact="true"
            path="/english-math-tutoring"
            element={<LowerLevelTutoring />}
          />
          {/* <Route
            exact="true"
            path="/scholarship-selective-school"
            element={<ScholarshipSelectiveSchool />}
          /> */}
          <Route
            exact="true"
            path="/vce-hsc-qce-tutoring"
            element={<VceTutoring />}
          />
          <Route
            exact="true"
            path="/vce-hsc-qce-essay-exam-marking"
            element={<VceEssayExamMarking />}
          />
          <Route
            exact="true"
            path="/vce-hsc-qce-study-guides"
            element={<VceStudyGuides />}
          />

          <Route
            exact="true"
            path="/master-classes"
            element={<MasterClasses />}
          />
          {/* <Route exact path="/private-tutoring" element={<PrivateTutoring />} /> */}

          {/* Contact */}
          {/* <Route exact path="/find-tutor" element={<FindTutor />} /> */}
          <Route exact="true" path="/work-with-us" element={<WorkWithUs />} />
          <Route exact="true" path="/contact" element={<Contact />} />

          {/* Legal */}
          {/* <Route exact path="/child-protection" element={<ChildProtection />} />
          <Route exact path="/privacy" element={<Privacy />} /> */}
          <Route exact="true" path="/policies" element={<Policies />} />
        </Routes>
        {/* <h1 className="text-4xl md:text-5xl font-bold text-blue tracking-wider mt-48 mb-20 text-center">
          Featured Instagram Posts
        </h1>
        <div className="grid grid-cols md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 justify-center w-[90%] mx-auto">
          <InstagramEmbed
            url="https://www.instagram.com/p/CvBTGa4PT-o/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CuwERkdNf90/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/CuOd4fZuTj-/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
          <InstagramEmbed
            url="https://www.instagram.com/p/Coio5q1PEbJ/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            width={328}
          />
        </div> */}
        <button
          className="sticky bottom-[10%] left-[80%] lg:left-[90%] z-[50] bg-transparent w-10 h-10"
          onClick={() => {
            animateScroll.scrollToTop();
          }}>
          <ArrowSmallUpIcon className="bg-blue rounded-full text-white ring ring-blue ring-offset-2 m-[0.35rem]" />
        </button>
      </main>

      {/* <MessengerCustomerChat pageId="636737876796707" appId="117423711033" /> */}
      {/* <MessengerChat
        pageId="636737876796707"
        language="en_AU"
        themeColor={"#000000"}
        bottomSpacing={300}
        loggedInGreeting="loggedInGreeting"
        loggedOutGreeting="loggedOutGreeting"
        greetingDialogDisplay={"show"}
        debugMode={true}
        onMessengerShow={() => {
          console.log("onMessengerShow");
        }}
        onMessengerHide={() => {
          console.log("onMessengerHide");
        }}
        onMessengerDialogShow={() => {
          console.log("onMessengerDialogShow");
        }}
        onMessengerDialogHide={() => {
          console.log("onMessengerDialogHide");
        }}
        // onMessengerMounted={() => {
        //   console.log("onMessengerMounted");
        // }}
        onMessengerLoad={() => {
          console.log("onMessengerLoad");
        }}
      /> */}
      <Footer />
    </div>
  );
};

export default App;
