import React from "react";
import questionIllustration from "../../components/assets/questionIllustration.png";
import { motion } from "framer-motion";
import { fadeRightToLeft, fadeUp } from "..";
// import pisaLogo from "../../components/assets/PISA logo.png";
// import timssLogo from "../../components/assets/timssLogo.png";

const MathTutoring = () => {
  const parent = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
        delayChildren: 1,
      },
    },
  };

  const lessonsFoundation = [
    "Mental maths and automatic response",
    "Number and place value",
    "The four operations of addition, subtraction, multiplication and division ",
    "Fractions",
    "Decimals",
    "Percentages",
  ];

  const secondaryStudentsFocus = [
    "Solving and graphing algebraic equations",
    "Rational and irrational numbers",
    "Trigonometric ratios",
    "Analysing and interpreting data using statistics and probability",
    "Financial mathematics",
    "Mathematical modelling",
  ];

  // const { scrollYProgress } = useScroll();
  // const x = useTransform(scrollYProgress, [0, 1], ["-200%", "100%"]);

  return (
    <section className="flex justify-center mt-0 lg:mt-10">
      <div className="flex flex-col items-center">
        {/* Illustration and question */}
        <div className="flex flex-col-reverse md:flex-row justify-center items-center">
          <img
            className="-translate-y-24 sm:translate-y-0 w-[24rem] h-[32rem]"
            src={questionIllustration}
          />
          <div className="relative flex flex-col items-center justify-center">
            <svg
              className="w-[24rem] sm:w-[32rem] h-[32rem]"
              width="1083"
              height="497"
              viewBox="0 0 1083 497"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.43631 453.239C29.6888 370.718 83.2116 288.197 122.936 209.115C143.843 166.917 190.257 110.34 194.857 65.0164C196.529 50.3252 215.346 39.3849 237.09 40.6352L1042.02 0C1063.77 1.25031 1080.07 14.3786 1078.82 29.0698L916.578 354.464C914.905 369.155 896.089 380.096 874.345 378.845L224.963 409.791C185.239 427.92 122.099 446.05 82.3753 463.867C37.6336 483.872 -16.7255 528.883 5.43631 453.239Z"
                fill="#0B4697"
              />
            </svg>
            <p className="absolute top-[10.5rem] sm:top-40 right-5 sm:right-10 text-center text-white font-semibold text-[0.98rem] sm:text-lg w-[80%] p-5">
              Are you among the growing number of parents becoming rightly
              worried that your child is not reaching their full potential in
              Mathematics?
            </p>
          </div>
        </div>
        {/* End - Illustration and question */}
        <h2 className="text-gray-500 text-2xl font-bold w-[60%] md:w-[70%] md:mt-20 text-center">
          Most of the time, this is due to gaps in basic number and mathematical
          skills, such as not being able to automatically recall times tables
          and lacking computational fluency in adding, subtracting, multiplying
          and dividing whole numbers. Another possible reason is the lack of
          conceptual understanding.
        </h2>
        {/* Information */}
        <div className="flex flex-col lg:flex-row justify-center mx-auto w-[95%] lg:w-[70%] gap-10 mt-20 border-2 border-purple rounded-3xl">
          <h2
            // variants={fadeRightToLeft}
            // initial={"hidden"}
            // whileInView={fadeRightToLeft.visible}
            // transition={{ duration: 2 }}
            // viewport={{ once: true }}
            className="text-white text-2xl font-semibold w-full p-10 bg-blue rounded-3xl bg-hero bg-contain">
            At TutorWo, we, alongside with the vast majority of parents,
            strongly believe solid foundational number and mathematical skills
            coupled with deep conceptual understanding of mathematical concepts
            and topics are non-negotiables in ensuring confidence, engagement
            and success in Mathematics. To achieve this, we centre our lessons
            on:
          </h2>
          <div className="pt-5 pb-12 pl-10 lg:p-5 rounded-3xl flex flex-col justify-center lg:w-[60%]">
            <motion.ul
              className="pl-5 list-disc text-lg text-gray-500 font-semibold gap-2"
              variants={parent}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}>
              {lessonsFoundation.map((focus, index) => (
                <li
                  key={"lessonsFoundation" + index}
                  // variants={fadeUp}
                  // whileInView={fadeUp.visible}
                  // transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                >
                  {focus}
                </li>
              ))}
            </motion.ul>
          </div>
        </div>
        {/* End- Information */}
        {/* Information 2*/}
        <div className="flex flex-col lg:flex-row justify-center mx-auto w-[95%] lg:w-[70%] gap-10 mt-20 border-2 border-purple rounded-3xl">
          <h2
            variants={fadeRightToLeft}
            initial={"hidden"}
            whileInView={fadeRightToLeft.visible}
            transition={{ duration: 2 }}
            viewport={{ once: true }}
            className="text-white text-2xl font-semibold w-full p-10 bg-purple rounded-3xl bg-hero bg-contain flex items-center">
            Additionally, our students in their secondary years of schooling
            focus on the following areas that will lay even stronger foundations
            for when they reach VCE:
          </h2>
          <div className="pt-5 pb-12 pl-10 lg:p-5 rounded-3xl flex flex-col justify-center lg:w-[60%]">
            <motion.ul
              className="pl-5 list-disc text-lg text-gray-500 font-semibold gap-2"
              variants={parent}
              initial={"hidden"}
              whileInView={"visible"}
              viewport={{ once: true }}>
              {secondaryStudentsFocus.map((focus, index) => (
                <li
                  key={"secondaryStudentsFocus" + index}
                  // variants={fadeUp}
                  // whileInView={fadeUp.visible}
                  // transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                >
                  {focus}
                </li>
              ))}
            </motion.ul>
          </div>
        </div>
        {/* End- Information 2*/}
        <h3 className="text-gray-500 w-[70%] md:w-[80%] text-center mt-20 text-lg">
          Our Mathematics lessons are inspired by best practice used in
          <span className="font-bold"> Singapore</span>. The nation has
          consistently been ranked as the top performer in international student
          benchmark tests, including the
          <span className="font-bold">
            {" "}
            Programme of International Student Assessment (PISA)
          </span>{" "}
          and the{" "}
          <span className="font-bold">
            {" "}
            Trends in International Mathematics and Science Study (TIMSS).{" "}
          </span>
          Our Mathematics lessons, particularly in the lower year levels,
          incorporate the core elements of Singapore Maths, including the use of
          the{" "}
          <span className="font-bold">
            Concrete-Pictorial-Abstract Approach
          </span>{" "}
          when teaching students new mathematical knowledge, skills and concepts
          to ensure deep understanding.
        </h3>
      </div>
    </section>
  );
};

export default MathTutoring;
