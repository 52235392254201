import React from "react";
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity,
} from "framer-motion";
import { fadeLeftToRight, fadeRightToLeft, fadeUp } from ".";
import tutorWoTeam from "./assets/tutorWoTeamTransparentDecorated.png";
import closerGap from "./assets/closerGapIllustration.png";
import tutorApproaches from "./assets/tutorApproachesIllustration.png";
import teacherExperience from "./assets/teachingExperienceIllustration.png";
import professionalTraining from "./assets/professionalTrainingIllustration.png";
import { Link } from "react-router-dom";
import { useRef } from "react";

const Tutors = () => {
  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], ["0%", "-300%"]);
  // const baseVelocity = 20;
  // const baseX = useMotionValue(0);
  // const scrollVelocity = useVelocity(scrollYProgress);
  // const smoothVelocity = useSpring(scrollVelocity, {
  //   damping: 50,
  //   stiffness: 400,
  // });
  // const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
  //   clamp: false,
  // });
  // // const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);
  // const x = useTransform(baseX, (v) => `${wrap(-400, -200, v)}%`);

  // const directionFactor = useRef(1);
  // useAnimationFrame((t, delta) => {
  //   let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

  //   /**
  //    * This is what changes the direction of the scroll once we
  //    * switch scrolling directions.
  //    */
  //   if (velocityFactor.get() < 0) {
  //     directionFactor.current = -1;
  //   } else if (velocityFactor.get() > 0) {
  //     directionFactor.current = 1;
  //   }

  //   moveBy += directionFactor.current * moveBy * velocityFactor.get();

  //   baseX.set(baseX.get() + moveBy);
  // });

  // const scrollVelocity = useVelocity(scrollYProgress);
  // const smoothVelocity = useSpring(scrollVelocity, {
  //   damping: 50,
  //   stiffness: 300,
  // });

  // const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
  //   clamp: false,
  // });

  // const x = useTransform(baseX, (v) => `${wrap(0, -25, v)}%`);
  // const directionFactor = useRef(1);

  // useAnimationFrame((t, delta) => {
  //   let moveBy = directionFactor.current * -5 * (delta / 1000);
  //   if (velocityFactor.get() < 0) {
  //     directionFactor.current = -1;
  //   } else if (velocityFactor.get() > 0) {
  //     directionFactor.current = 1;
  //   }
  // });
  // if (velocityFactor.get() !== 0) {
  //   moveBy += directionFactor.current * moveBy * velocityFactor.get();
  //   baseX.set(baseX.get() + moveBy);
  // }

  return (
    <section className="flex flex-col mt-20 gap-10 overflow-hidden">
      <div className="w-full lg:w-[80%] flex flex-col gap-2 mx-auto">
        {/* <motion.h3
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-center text-lg font-semibold tracking-wider text-purple uppercase">
          TutorWo Team
        </motion.h3> */}
        <motion.h1
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="text-center text-5xl font-bold text-blue mb-5">
          Our Tutors
        </motion.h1>

        <motion.div
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="flex flex-col lg:gap-5 text-xl text-gray-500">
          <div className="flex flex-col justify-center items-center gap-5">
            <img
              className="w-[26rem] h-96"
              src={tutorWoTeam}
              alt="a photo of two team members from tutorWo"
            />
            <div className="flex flex-col gap-5 w-[80%]">
              <p>
                Educational research points to the quality of teachers and
                tutors as the most important contributor to students’ academic
                success. To this end, we have a rigorous screening and selection
                process that will ensure students are only taught by the cream
                of the crop.
              </p>
              <p>
                Our team members consist of top ATAR graduates and
                high-performing school teachers, giving our students the best of
                both worlds.
              </p>
            </div>
          </div>
          {/* Advantages of top ATAR VCE graduate as tutor */}
          <p className="font-bold text-3xl text-center text-purple my-5 mx-auto w-[80%] md:w-full">
            There are many advantages of having a top ATAR VCE / HCE / QCE
            graduate as a tutor
          </p>
          <div className="flex flex-col sm:gap-10 md:flex-row items-center mx-auto w-[80%]">
            <motion.div
              variants={fadeLeftToRight}
              initial={"hidden"}
              whileInView={fadeLeftToRight.visible}
              transition={{ duration: 1 }}
              className="md:w-[40%] flex justify-center">
              <img
                className="w-[70%] h-[70%] md:w-64 md:h-64 mb-5 md:mb-0"
                src={closerGap}></img>
            </motion.div>
            <p className="md:w-[60%]">
              A closer age gap between the tutor and student means both share
              similar methods of communicating. The tutor is able to use
              language at the same level as the student when explaining and
              breaking down topics and concepts. This is highly invaluable in
              ensuring student understanding and engagement.
            </p>
          </div>
          <div className="flex flex-col-reverse md:flex-row items-center mx-auto w-[80%]">
            <p className="md:w-[60%] md:ml-20">
              Top ATAR scorers have each developed their own proven approaches
              to achieving academic success. Given they are fresh out of school,
              they will be able to share with their students the most up-to-date
              and relevant advice on what it takes to excel in school and
              ultimately, the VCE / HCE / QCE.
            </p>
            <motion.div
              variants={fadeRightToLeft}
              initial={"hidden"}
              whileInView={fadeRightToLeft.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="mt-10 sm:mt-0 md:w-[40%] flex justify-center">
              <img
                className="md:w-96 md:h-72 mb-5 md:mb-0"
                src={tutorApproaches}
                alt="Tutor approach to achivieving academic success"></img>
            </motion.div>
          </div>
          {/* Advantages of school teacher as tutor */}
          <p className="font-bold text-3xl text-center text-purple my-5 mx-auto w-[80%] lg:w-full">
            Equally, there are also many advantages of having a school teacher
            as a tutor
          </p>
          <div className="flex flex-col gap-5 xl:gap-0 md:flex-row items-center mx-auto w-[80%]">
            <motion.div
              variants={fadeLeftToRight}
              initial={"hidden"}
              whileInView={fadeLeftToRight.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="md:w-[40%] flex justify-center">
              <img
                className="w-[70%] h-[70%] md:w-72 md:h-72 mb-5 md:mb-0"
                src={teacherExperience}></img>
            </motion.div>
            <p className="md:w-[60%]">
              School teachers possess years of teaching experience in their
              subject areas. They know what works and what does not work when it
              comes to delivering content and designing learning activities.
            </p>
          </div>
          <div className="flex flex-col-reverse gap-5 xl:gap-0 md:flex-row items-center mx-auto w-[80%] mb-20 sm:mb-0">
            <p className="md:w-[60%] md:ml-20">
              School teachers undertake regular professional learning to keep
              them abreast with the latest updates, developments and research in
              their teaching areas. They also engage in collaboration with other
              teachers from and beyond their own schools where teaching ideas,
              expertise and best practice are shared.
            </p>
            <motion.div
              variants={fadeRightToLeft}
              initial={"hidden"}
              whileInView={fadeRightToLeft.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="md:w-[40%] flex justify-center mt-10 sm:mt-0">
              <img
                className="w-[70%] h-[70%] md:w-72 md:h-[16.5rem] mb-5 md:mb-0"
                src={professionalTraining}></img>
            </motion.div>
          </div>
          <div className="sm:mt-20 lg:mt-5 flex flex-col justify-center items-center text-center gap-10 mx-auto w-[80%] lg:w-full">
            <p className="text-blue font-semibold">
              Our team members possess the natural ability to build strong
              rapport and communicate effectively to ensure optimal learning.
              They are also highly accomplished and successful role models. Our
              team members draw from their own life experiences and achievements
              to inspire our students to be their best selves, not just
              academically but in life too.
            </p>
            <p className="italic">Click below to learn more about our people</p>
          </div>
        </motion.div>

        <motion.div
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="flex mt-10 mb-60 mx-auto">
          <Link to="/our-tutors">
            <button className="uppercase block w-30 py-5 px-10 rounded-md shadow bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
              Meet the tutors
            </button>
          </Link>
          {/* <Link to="/private-tutoring">
                <button className="block mx-4 w-30 py-3 px-4 rounded-md shadow  bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link> */}
        </motion.div>
      </div>
    </section>
  );
};

export default Tutors;
