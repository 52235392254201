import React, { useRef } from "react";
import vceTutoring from "../../assets/vceTutoringIllustration.png";
import ServicesHero from "../../ServicesHero";
import {
  BookOpenIcon as BookOpenIconOutline,
  DocumentTextIcon as DocumentTextIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  BoltIcon as BoldIconOutline,
  UserIcon,
  ChatBubbleBottomCenterTextIcon,
  FaceSmileIcon,
  FlagIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleLeftRightIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import yellowDottedPath from "../../assets/vectors/yellowDottedPath.svg";
import orangeDottedPath from "../../assets/vectors/orangeDottedPath.svg";
import { fadeDown, fadeLeftToRight, fadeRightToLeft, fadeUp } from "../..";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import privateTutoringIcon from "../../assets/ServicesIcons/private-tutoring-icon.png";
import groupTutoringIcon from "../../assets/ServicesIcons/group-tutoring-icon.png";
import questionOne from "../../assets/vceTutoringQuestionsChatBox/questionOne.png";
import questionTwo from "../../assets/vceTutoringQuestionsChatBox/questionTwo.png";
import questionThree from "../../assets/vceTutoringQuestionsChatBox/questionThree2.png";
import questionOneIllustration from "../../assets/vceTutoringQuestionsChatBox/questionOneIllustration.svg";
import questionTwoIllustration from "../../assets/vceTutoringQuestionsChatBox/questionTwoIllustration.svg";
import questionThreeIllustration from "../../assets/vceTutoringQuestionsChatBox/questionThreeIllustration.svg";
import VceGroupSubjects from "../subjects/VceGroupSubjects";
import OnlineLearning from "../../OnlineLearning";
import PrivateGroupTutor from "../../PrivateGroupTutor";

const VceTutoring = () => {
  const boostConfidence = [
    {
      name: "pointOne",
      icon: DocumentTextIconOutline,
      color: "text-orange",
      heading: "Simplying complex topics",
      text: "Unpacking and simplifying complex concepts and topics in ways that make it easier for you to understand",
    },
    {
      name: "pointTwo",
      icon: BookOpenIconOutline,
      color: "text-yellow",
      heading: "Sharing our secret recipe",
      text: "Sharing with you our very own recipe to formulating winning answers and responses to internal school assessment and external exam questions",
    },
    {
      name: "pointThree",
      icon: UserGroupIconOutline,
      color: "text-orange",
      heading: "Giving multiple perspectives",
      text: "Providing you with multiple perspectives in areas where teacher and examiner subjectivity comes into play in the marking of internal school assessment and external exams",
    },
    {
      name: "pointFour",
      icon: BoldIconOutline,
      color: "text-yellow",
      heading: "Transferring powerful strategies",
      text: "Equipping you with our very own powerful study and memorisation strategies",
    },
  ];

  const addValue = [
    {
      name: "pointOne",
      icon: ChatBubbleLeftRightIcon,
      color: "text-purple",
      heading: "Bouncing ideas",
      text: "Being your sounding board for ideas and concerns (getting diverse opinions better informs your decision making)",
    },
    {
      name: "pointTwo",
      icon: UsersIcon,
      color: "text-purple",
      heading: "Providing guidance",
      text: "Advising you on what to expect for the year ahead and how to put your best foot forward in managing and juggling the competing demands throughout your most crucial years of schooling.",
    },
  ];

  const individualisedTutoring = [
    {
      name: "pointOne",
      icon: UserIcon,
      borderColor: "border-yellow",
      color:
        "bg-yellow rounded-full text-gray-700 px-4 py-2 border-grey border-2",
      heading: "Targeted Assistance",
      text: "Devoting more time to areas where you need the most help, which includes having difficult-to-understand concepts and topics explained to you again and again until you get it.",
    },
    {
      name: "pointTwo",
      icon: ChatBubbleBottomCenterTextIcon,
      borderColor: "border-blue",
      color: "bg-blue rounded-full text-white px-4 py-2 border-grey border-2",
      heading: "Customised Feedback",
      text: "Receiving comprehensive and highly targeted feedback so you know exactly what you need to do to produce winning answers and responses in upcoming internal school assessment and external exams",
    },
    {
      name: "pointTwo",
      icon: FaceSmileIcon,
      borderColor: "border-purple",
      color: "bg-purple rounded-full text-white px-4 py-2 border-grey border-2",
      heading: "Neutral Observer",
      text: "Having a fresh pair of eyes with us acting as your neutral observer in how you can do even better in approaching and managing the demands of the VCE / HSC / QCE.",
    },
    {
      name: "pointTwo",
      icon: FlagIcon,
      borderColor: "border-orange",
      color:
        "bg-orange rounded-full text-gray-700 px-4 py-2 border-grey border-2",
      heading: "Sustained Progress",
      text: "Ensuring you remain on track to the very end.",
    },
  ];

  const privateTutor = {
    heading: "Private Tutoring",
    body: "Highly individualised one-to-one tutoring enables targeted instruction where every session is personalised and tailored to your very own specific learning needs and goals.",
    className: "xl:h-[33rem]",
  };

  const groupTutor = {
    heading: "Small Group Tutoring",
    body: "Small group tutoring offers a more budget-friendly option where you will experience the perfect blend of collaborative learning and individualised attention. Our classes are carefully sized to still allow for targeted instruction, guidance and support.",
    className: "md:translate-y-10 xl:h-[36rem]",
  };

  // const parent = {
  //   hidden: { opacity: 0 },
  //   visible: {
  //     opacity: 1,
  //     transition: {
  //       staggerChildren: 0.6,
  //       delayChildren: 1.5,
  //     },
  //   },
  // };

  // const [mouseOver, setMouseOver] = useState(false);

  // console.log(mouseOver);

  // const handleMouseOver = () => {
  //   setMouseOver(true);
  // };
  const headingRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    target: headingRef,
    offset: ["start center", "end end"],
  });

  const x = useTransform(scrollYProgress, [0, 1], [-200, 500]);

  const questionOneRef = useRef(null);
  const questionOneInView = useInView(questionOneRef);
  const questionTwoRef = useRef(null);
  const questionTwoInView = useInView(questionTwoRef);
  const questionThreeRef = useRef(null);
  const questionThreeInView = useInView(questionThreeRef);

  return (
    <section className="flex flex-col justify-center items-center mt-20 overflow-hidden">
      <ServicesHero
        // firstHeading="Tutoring"
        secondHeading="VCE / HSC / QCE Tutoring"
        headingDescription="Our team of top ATAR tutors (who have all been there and done that) and high-performing school teachers
        understand firsthand the challenges you face with the rollercoaster of being a VCE / HSC / QCE student."
        leftTag="Individualised tutoring"
        rightTag="Unlock VCE / HSC / QCE success"
        image={vceTutoring}
        imageDescription="Illustration of scholarship selective school exam"
      />
      <div className="relative flex flex-col bg-purple bg-spreadedDots">
        <svg
          style={{
            marginTop: "-1px",
            marginBottom: "-2px",
            strokeWidth: 0,
          }}
          viewBox="0 0 1200 120"
          className="w-[150%]">
          <path
            d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z"
            fill="white"></path>
        </svg>
        <div className="flex flex-col lg:flex-row justify-center items-center mx-auto gap-20 sm:gap-48 lg:gap-10 py-16 md:w-[80%] relative">
          <h2
            // variants={fadeDown}
            // initial={"hidden"}
            // whileInView={fadeDown.visible}
            // transition={{ duration: 1 }}
            // viewport={{ once: true }}
            className="text-5xl text-white w-[90%] lg:w-[60%] tracking-wide font-bold leading-[3.5rem]">
            Our team of experts will boost your{" "}
            <span className="text-yellow">confidence</span> and help you unlock
            VCE / HSC / QCE <span className="text-yellow">success</span> by:
          </h2>

          <div className="relative flex flex-col">
            {/* <svg
              className="absolute -bottom-36 sm:-bottom-52 lg:-bottom-60 left-0 w-[48rem] md:w-[50rem]"
              width="967"
              height="934"
              viewBox="0 0 967 934"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 927V166.387C218.75 123.614 653.837 95.0362 844.662 86.0938V876.557C578.882 817.159 174.146 885.437 5 927Z"
                stroke="#FCC219"
                strokeWidth="10"
                strokeLinecap="round"
                strokeDasharray="20 20"
              />
              <path
                d="M905.827 368.303L493.445 65.3502L554.611 0L966.993 308.997L905.827 368.303Z"
                fill="#D9D9D9"
              />
            </svg> */}
            {boostConfidence.map((point, index) => (
              <motion.div
                initial={"hidden"}
                variant={fadeLeftToRight}
                key={`confidence_${index}`}
                whileInView={fadeLeftToRight.visible}
                className="flex flex-col gap-5 text-white w-[80%] mx-auto">
                <motion.div
                  initial={"hidden"}
                  variant={fadeUp}
                  key={`confidence_text_${index}`}
                  whileInView={fadeLeftToRight.visible}
                  transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                  className={`flex items-center gap-5 ${point.color}`}>
                  {/* // className={`${point.color}`} */}
                  <point.icon
                    className={`w-10 h-10`}
                    initial={"hidden"}
                    variant={fadeLeftToRight}
                    key={`confidence_${index}`}
                    whileInView={fadeLeftToRight.visible}
                    transition={{ duration: 1 }}
                  />
                  <h2 className="font-semibold text-lg">{point.heading}</h2>
                </motion.div>
                <motion.p
                  variants={fadeLeftToRight}
                  initial={"hidden"}
                  // variant={fadeLeftToRight}
                  key={`confidence_${index}`}
                  whileInView={fadeLeftToRight.visible}
                  transition={{ duration: 1 }}
                  // whileInView={fadeUp.visible}
                  // transition={{ duration: 1, delay: 0.5 }}
                  // viewport={{ once: true }}
                  className="mb-5">
                  {point.text}
                </motion.p>
                {/* </motion.div> */}
              </motion.div>
            ))}
          </div>
        </div>
        <div className="absolute bottom-0">
          <svg
            width="2369"
            height="6"
            viewBox="0 0 2369 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.29297 2.875H2365.53"
              stroke="#FCC219"
              strokeWidth="5"
              strokeLinecap="round"
              strokeDasharray="20 20"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-col lg:flex-cols justify-center items-center mt-10 lg:mt-0 mx-auto gap-10 sm:gap-48 lg:gap-10 md:w-[80%]">
        <h2
          // variants={fadeDown}
          // initial={"hidden"}
          // whileInView={fadeDown.visible}
          // transition={{ duration: 1 }}
          // viewport={{ once: true }}
          className="text-5xl text-purple w-[90%] lg:w-[80%] tracking-wide font-bold leading-[3.5rem] xl:mt-20">
          We also know how invaluable mentoring is in navigating your way
          through VCE / HSC / QCE, which we{" "}
          <span className="text-yellow">add value</span> by:
        </h2>
        {addValue.map((point, index) => (
          <div
            key={`addValue-${index}`}
            className="flex flex-col gap-5 lg:mt-3 mx-auto w-[90%] lg:w-[80%]">
            <motion.div
              initial={"hidden"}
              variant={fadeUp}
              key={`value_container_${index}`}
              whileInView={fadeLeftToRight.visible}
              transition={{ duration: 1 }}
              className={`flex items-center gap-5 ${point.color}`}>
              <point.icon
                className={`w-10 h-10`}
                initial={"hidden"}
                variant={fadeLeftToRight}
                whileInView={fadeLeftToRight.visible}
                transition={{ duration: 1 }}
              />
              <h2 className="font-semibold text-lg">{point.heading}</h2>
            </motion.div>
            <motion.p
              variants={fadeLeftToRight}
              initial={"hidden"}
              key={`value_text_${index}`}
              whileInView={fadeLeftToRight.visible}
              transition={{ duration: 1 }}
              className={`text-gray-500 mb-5`}>
              {point.text}
            </motion.p>
          </div>
        ))}
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSfY3ByOMjButSfE_s9u2uVJsmpsqTkxPbuFK22o62cMavtgIw/viewform?usp=sf_link"
          target="_blank"
          rel="noreferrer">
          <button className="flex w-40 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
            Enquire Now
            <ArrowTopRightOnSquareIcon className="h-5 w-5 ml-2" />
          </button>
        </a>
      </div>
      <div
        ref={headingRef}
        className="flex flex-col justify-center items-center mt-48 w-[90%]">
        {/* <motion.h1
          style={{ x }}
          className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
          Subjects we offer
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
          <motion.span className="ml-10 text-blue text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
          <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeTextBlue font-bold whitespace-nowrap">
            Subjects we offer
          </motion.span>
        </motion.h1> */}
        <h1 className="mx-2 text-5xl text-center font-bold text-blue">
          What subjects do we offer?
        </h1>
        {/* Subjects we offer */}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mt-10 md:mt-20 gap-5 w-[90%] text-center">
          <VceGroupSubjects subject="VCE" />
          <VceGroupSubjects subject="HSC" />
          <VceGroupSubjects subject="QCE" />
        </div>
        <motion.h1
          variants={fadeUp}
          initial={"hidden"}
          whileInView={fadeUp.visible}
          transition={{ duration: 1, delay: 0.3 }}
          viewport={{ once: true }}
          className="mx-2 mt-20 text-5xl font-bold text-blue">
          How can we help?
        </motion.h1>
        <div className=" flex flex-col justify-center items-center mt-12 gap-20">
          {/* Question box 1 */}
          <div className="flex gap-10 w-[60%] md:w-full">
            <img
              className="hidden md:inline w-56 h-56"
              src={questionOneIllustration}
              alt="chat box with doodled circles as background"
            />
            <div
              ref={questionOneRef}
              className={`w-[36rem] h-[8rem] border-2 border-purple rounded-full translate-y-16 flex justify-center ${
                questionOneInView ? "" : "overflow-hidden"
              }`}>
              <motion.div
                intial={{ y: 100 }}
                whileInView={{ y: -50 }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 0.8,
                }}
                viewport={{ once: true }}
                className="bg-white">
                <img
                  className="w-[20rem] h-[6rem] md:w-[32rem] md:h-[8rem] translate-y-5 md:translate-y-0"
                  src={questionOne}
                  alt="chat box with doodled circles as background"
                />
              </motion.div>
            </div>
          </div>
          {/* End- Question box 1 */}
          {/* Question box 2 */}
          <div className="flex gap-10 w-[60%] md:w-full">
            <div
              ref={questionTwoRef}
              className={`w-[36rem] h-[8rem] border-2 border-yellow rounded-full translate-y-16 flex justify-center ${
                questionTwoInView ? "" : "overflow-hidden"
              }`}>
              <motion.div
                intial={{ y: 100 }}
                whileInView={{ y: -50 }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 0.8,
                }}
                viewport={{ once: true }}
                className="bg-white">
                <img
                  className="w-[20rem] h-[6rem] md:w-[32rem] md:h-[8rem] translate-y-5 md:translate-y-0"
                  src={questionTwo}
                  alt="chat box with doodled circles as background"
                />
              </motion.div>
            </div>
            <img
              className="hidden md:inline w-56 h-56"
              src={questionTwoIllustration}
              alt="chat box with doodled circles as background"
            />
          </div>
          {/* End- Question box 2 */}
          {/* Question box 3 */}
          <div className="flex gap-10 w-[60%] md:w-full">
            <img
              className="hidden md:inline w-56 h-56"
              src={questionThreeIllustration}
              alt="chat box with doodled circles as background"
            />
            <div
              ref={questionThreeRef}
              className={`w-[36rem] h-[8rem] border-2 border-blue rounded-full translate-y-16 flex justify-center ${
                questionThreeInView ? "" : "overflow-hidden"
              }`}>
              <motion.div
                intial={{ y: 100 }}
                whileInView={{ y: -80 }}
                transition={{
                  type: "spring",
                  bounce: 0.5,
                  duration: 0.8,
                }}
                viewport={{ once: true }}
                className="bg-white">
                <img
                  className="w-[20rem] h-[8rem] translate-y-5 md:translate-y-0 md:w-[32rem] md:h-[10rem]"
                  src={questionThree}
                  alt="chat box with doodled circles as background"
                />
              </motion.div>
            </div>
          </div>
          {/* End- Question box 3 */}
        </div>
        <div className="flex flex-col lg:flex-row w-[90%] lg:w-[80%] mt-36 gap-20">
          <h2 className="text-purple font-bold text-3xl leading-normal">
            {/* Highly <span className="text-yellow">individualised</span>{" "}
            one-to-one tutoring enables targeted instruction where every session
            is tailored to your specific learning needs and goals. This includes
            but not limited to: */}
            Our tutors will work with you to{" "}
            <span className="text-yellow">tailor</span> every tutoring session
            to your learning needs and goals. This includes but not limited to:
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {individualisedTutoring.map((point, index) => (
              <div
                key={`individualisedTutoring_${index}`}
                className={`border-4 ${point.borderColor} rounded-3xl p-5`}>
                <motion.div
                  // variants={fadeDown}
                  // initial={"hidden"}
                  // whileInView={fadeDown.visible}
                  // transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                  className={`flex items-center gap-5 ${point.color}`}>
                  <point.icon className={`w-10 h-10 `} />
                  <h2 className="font-semibold text-lg">{point.heading}</h2>
                </motion.div>
                <motion.p
                  variants={fadeUp}
                  initial={"hidden"}
                  whileInView={fadeUp.visible}
                  transition={{ duration: 1 }}
                  // viewport={{ once: true }}
                  className="mb-5 text-gray-500 mt-2">
                  {point.text}
                </motion.p>
              </div>
            ))}
          </div>
        </div>
        <img className="mt-20 max-w-[150%]" src={yellowDottedPath} />

        {/* Combined in one line private + Small group */}
        <PrivateGroupTutor
          privateTutor={privateTutor}
          groupTutor={groupTutor}
        />
        {/* END - Combined private + small group */}
        <img className="mt-40 max-w-[150%]" src={orangeDottedPath} />
      </div>
      <OnlineLearning />
    </section>
  );
};

export default VceTutoring;
