import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import { fadeDown, fadeUp } from ".";

const MarkingInvestment = () => {
  const investmentRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    // target: investmentRef,
    // offset: ["start center", "end end"],
  });

  const x = useTransform(scrollYProgress, [0, 1], [-800, -1500]);

  return (
    <section ref={investmentRef} className="md:mt-40 mb-20">
      <motion.h1
        style={{ x }}
        className="md:mb-5 text-2xl sm:text-3xl md:text-7xl text-transparent font-bold whitespace-nowrap">
        Investment
        <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-purple text-2xl sm:text-3xl md:text-7xl font-bold whitespace-nowrap">
          Investment •
        </motion.span>
        <motion.span className="ml-10 text-transparent text-2xl sm:text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
          Investment •
        </motion.span>
      </motion.h1>
      {/* Investment box */}
      <div className="sm:w-[70%] md:w-[50%] lg:w-[20%] mx-auto mt-20">
        {/* Investment 1 */}
        {/* <motion.div
          variants={fadeDown}
          initial={"hidden"}
          whileInView={fadeDown.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="bg-purple  rounded-3xl w-[19rem] h-64">
          <div className="flex flex-col border-[3px] border-yellow p-5 rounded-3xl justify-center items-center gap-3 w-[19rem] h-64 -translate-x-2 -translate-y-1">
            <h2 className="text-5xl text-white pb-3">$35</h2>
            <div className="flex flex-col justify-center items-center border-t-2 border-gray-300 pt-3 gap-4">
              <div className="flex justify-center gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 12H15M9 16H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="text-yellow font-semibold">one essay</p>
              </div>
              <a href="mailto:tutorwo.tutoring@gmail.com">
                <button className="text-white border-2 border-white rounded-full px-5 py-1 hover:border-yellow hover:text-yellow">
                  Book now
                </button>
              </a>
            </div>
          </div>
        </motion.div> */}
        {/* End of Investment 1 */}
        {/* Investment 2 */}
        <motion.div
          variants={fadeDown}
          initial={"hidden"}
          whileInView={fadeDown.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="flex items-center bg-purple rounded-3xl w-[19rem] h-64">
          <div className="flex flex-col border-[3px] border-yellow p-5 rounded-3xl justify-center items-center gap-3 w-[19rem] h-64 -translate-x-2 -translate-y-1">
            <h2 className="text-5xl text-white pb-3">$50</h2>
            <div className="flex flex-col justify-center items-center border-t-2 border-gray-300 pt-3 gap-4">
              <div className="flex justify-center gap-4">
                <svg
                  width="42"
                  height="30"
                  viewBox="0 0 42 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 5C5 3.61929 6.11929 2.5 7.5 2.5H13.2322C13.8953 2.5 14.5312 2.76339 15 3.23223L19.2678 7.5C19.7366 7.96884 20 8.60473 20 9.26777V20C20 21.3807 18.8807 22.5 17.5 22.5H7.5C6.11929 22.5 5 21.3807 5 20V5ZM7.5 12.5C7.5 11.8096 8.05964 11.25 8.75 11.25H16.25C16.9404 11.25 17.5 11.8096 17.5 12.5C17.5 13.1904 16.9404 13.75 16.25 13.75H8.75C8.05964 13.75 7.5 13.1904 7.5 12.5ZM8.75 16.25C8.05964 16.25 7.5 16.8096 7.5 17.5C7.5 18.1904 8.05964 18.75 8.75 18.75H16.25C16.9404 18.75 17.5 18.1904 17.5 17.5C17.5 16.8096 16.9404 16.25 16.25 16.25H8.75Z"
                    fill="white"
                  />
                  <path
                    d="M27 18H33M27 22H33M35 27H25C23.8954 27 23 26.1046 23 25V11C23 9.89543 23.8954 9 25 9H30.5858C30.851 9 31.1054 9.10536 31.2929 9.29289L36.7071 14.7071C36.8946 14.8946 37 15.149 37 15.4142V25C37 26.1046 36.1046 27 35 27Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="text-yellow font-semibold">One essay</p>
              </div>
              <a href="mailto:tutorwo.tutoring@gmail.com">
                <button className="text-white border-2 border-white rounded-full px-5 py-1 hover:border-yellow hover:text-yellow">
                  Book now
                </button>
              </a>
            </div>
          </div>
        </motion.div>
        {/* End of Investment 2 */}
        {/* Investment 3 */}
        {/* <motion.div
          variants={fadeDown}
          initial={"hidden"}
          whileInView={fadeDown.visible}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="bg-purple  rounded-3xl w-[19rem] h-64">
          <div className="flex flex-col border-[3px] border-yellow p-5 rounded-3xl justify-center items-center gap-3 w-[19rem] h-64 -translate-x-2 -translate-y-1">
            <h2 className="text-5xl text-white pb-3">$70</h2>
            <div className="flex flex-col justify-center items-center border-t-2 border-gray-300 pt-3 gap-4">
              <div className="flex justify-center gap-4">
                <svg
                  width="49"
                  height="31"
                  viewBox="0 0 49 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 12H15M9 16H15M17 21H7C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H12.5858C12.851 3 13.1054 3.10536 13.2929 3.29289L18.7071 8.70711C18.8946 8.89464 19 9.149 19 9.41421V19C19 20.1046 18.1046 21 17 21Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 11C17 9.61929 18.1193 8.5 19.5 8.5H25.2322C25.8953 8.5 26.5312 8.76339 27 9.23223L31.2678 13.5C31.7366 13.9688 32 14.6047 32 15.2678V26C32 27.3807 30.8807 28.5 29.5 28.5H19.5C18.1193 28.5 17 27.3807 17 26V11ZM19.5 18.5C19.5 17.8096 20.0596 17.25 20.75 17.25H28.25C28.9404 17.25 29.5 17.8096 29.5 18.5C29.5 19.1904 28.9404 19.75 28.25 19.75H20.75C20.0596 19.75 19.5 19.1904 19.5 18.5ZM20.75 22.25C20.0596 22.25 19.5 22.8096 19.5 23.5C19.5 24.1904 20.0596 24.75 20.75 24.75H28.25C28.9404 24.75 29.5 24.1904 29.5 23.5C29.5 22.8096 28.9404 22.25 28.25 22.25H20.75Z"
                    fill="white"
                  />
                  <path
                    d="M34 12H40M34 16H40M42 21H32C30.8954 21 30 20.1046 30 19V5C30 3.89543 30.8954 3 32 3H37.5858C37.851 3 38.1054 3.10536 38.2929 3.29289L43.7071 8.70711C43.8946 8.89464 44 9.149 44 9.41421V19C44 20.1046 43.1046 21 42 21Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="text-yellow font-semibold">three essays</p>
              </div>
              <div className="flex justify-center gap-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>

                <p className="text-yellow font-semibold">
                  a full English examination
                </p>
              </div>
              <a href="mailto:tutorwo.tutoring@gmail.com">
                <button className="text-white border-2 border-white rounded-full px-5 py-1 hover:border-yellow hover:text-yellow">
                  Book now
                </button>
              </a>
            </div>
          </div>
        </motion.div> */}
        {/* End of Investment 3 */}
      </div>
    </section>
  );
};

export default MarkingInvestment;
