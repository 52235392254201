import React from "react";
// import cloud from "../components/assets/vectors/cloud.svg";
import { useRef } from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const VceGroupSubjects = ({ subject }) => {
  const sectionRef = useRef();

  // Heading scrolling effect
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start center", "end end"],
  });

  const temporaryY = useSpring(scrollYProgress, {
    stiffness: 200,
    damping: 10,
    restDelta: 0.001,
  });
  const y1 = useTransform(temporaryY, [0, 1], [-50, 300]);
  const y2 = useTransform(temporaryY, [0, 1], [-50, 200]);
  const opacity = useTransform(scrollYProgress, [0, 0.4], [0, 1]);

  const vceSubjects = [
    {
      name: "Biology",
    },
    {
      name: "Chemistry",
    },
    {
      name: "Chinese Second Language",
    },
    {
      name: "Chinese Second Language Advanced",
    },
    {
      name: "English",
    },
    {
      name: "English Language",
    },
    {
      name: "English Literature",
    },
    {
      name: "Mathematics - General",
    },
    {
      name: "Mathematics - Methods",
    },
    {
      name: "Physics",
    },
  ];

  const hscSubjects = [
    {
      name: "English - Standard",
    },
    {
      name: "English - Advanced",
    },
    {
      name: "Mathematics - Standard",
    },
    {
      name: "Mathematics - Advanced",
    },
    {
      name: "Mathematics - Extension 1",
    },
  ];

  const qceSubjects = [
    {
      name: "Biology",
    },
    {
      name: "Chemistry",
    },
    {
      name: "Economics",
    },
    {
      name: "English",
    },
    {
      name: "Mathematics - General",
    },
    {
      name: "Mathematics - Methods",
    },
    {
      name: "Mathematics - Specialist",
    },
    {
      name: "Physics",
    },
  ];

  return (
    <section ref={sectionRef} className="md:mb-52">
      {/* SUBJECTS */}
      <div className="relative flex flex-col gap-2 h-auto md:h-[32rem] items-center w-full bg-purple rounded-3xl">
        <div className="flex flex-col border-[2px] border-yellow rounded-3xl justify-start py-10 items-center gap-3 h-full w-full -translate-x-2 -translate-y-1">
          {/* <div className="ring ring-blue ring-offset-1 rounded-full mx-14 p-2"> */}
          <h2 className="text-yellow font-bold text-xl text-center mb-5 mx-10">
            {subject === "VCE"
              ? "Victorian Certificate of Education (VCE)"
              : subject === "HSC"
              ? "New South Wales High School Certificate (HSC)"
              : subject === "QCE"
              ? "Queensland Certificate of Education (QCE)"
              : null}
          </h2>
          {/* </div> */}
          {subject === "VCE" &&
            vceSubjects.map((subject, index) => (
              <p key={`vceSubject_${index}`} className="text-white">
                {subject.name}
              </p>
            ))}
          {subject === "HSC" &&
            hscSubjects.map((subject, index) => (
              <p key={`hscSubject_${index}`} className="text-white">
                {subject.name}
              </p>
            ))}
          {subject === "QCE" &&
            qceSubjects.map((subject, index) => (
              <p key={`qceSubject_${index}`} className="text-white">
                {subject.name}
              </p>
            ))}
        </div>
      </div>
      {/* END SUBJECTS */}
    </section>
  );
};

export default VceGroupSubjects;
