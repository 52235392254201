import React, { useRef } from "react";
// Illustrations
import mathEnglishTutoring from "./assets/englishMathTutoringIllustration.png";
import vceTutoring from "./assets/vceTutoringIllustration.png";
import scholarshipExam from "./assets/scholarshipSelectiveExamIllustration.png";
import essayMarking from "./assets/essayMarkingIllustration.png";
import vceStudyGuide from "./assets/studyGuide.png";
import {
  motion,
  useAnimation,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";
import { fadeLeftToRight, fadeRightToLeft, fadeUp } from ".";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Services = ({ servicesRef }) => {
  // Heading scrolling effect
  const { scrollYProgress } = useScroll();

  const x = useTransform(scrollYProgress, [0, 1], [-600, 2400]);

  return (
    <section
      id="services"
      ref={servicesRef}
      className="flex flex-col overflow-hidden border-none">
      {/* TITLE - DISCOVER HOW WE CAN HELP */}
      <div className="relative mb-10 md:mb-28">
        <motion.h1
          style={{ x }}
          className="absolute -left-[10%] text-3xl md:text-7xl text-purple font-bold whitespace-nowrap">
          DISCOVER HOW WE CAN HELP
          <motion.span className="ml-10 text-transparent text-3xl md:text-7xl strokeText font-bold whitespace-nowrap">
            DISCOVER HOW WE CAN HELP
          </motion.span>
          <motion.span className="ml-10 text-purple text-3xl md:text-7xl font-bold whitespace-nowrap">
            DISCOVER HOW WE CAN HELP
          </motion.span>
        </motion.h1>
      </div>
      {/* END TITLE - DISCOVER HOW WE CAN HELP */}
      {/* Year 3 - 10 Math & English Tutoring */}
      <div className="flex flex-col items-center md:flex-row">
        {/* Left side */}
        <div className="md:w-[50%]">
          <motion.img
            variants={fadeLeftToRight}
            initial={"hidden"}
            whileInView={fadeLeftToRight.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="mx-auto mt-20 md:m-20 w-[70%] h-[80%]"
            src={mathEnglishTutoring}></motion.img>
        </div>
        {/* Right side */}
        <div className="md:w-[50%] flex flex-col justify-center items-center">
          <div className="w-[80%] flex flex-col gap-5">
            {/* <motion.h3
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-lg font-semibold tracking-wider text-purple uppercase">
              Tutoring
            </motion.h3> */}
            <motion.h1
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-5xl font-bold text-blue">
              Years 3 to 10 English and Mathematics Tutoring
            </motion.h1>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-xl text-gray-500">
              Does your child have gaps in reading, writing and basic number and
              mathematical skills that are preventing them from reaching their
              potential? We are specialists in laying strong foundations in
              English and Mathematics by using research-proven teaching
              methodologies trialled and tested in top-performing educational
              systems internationally.
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="flex">
              <Link to="/english-math-tutoring">
                <button className="block w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link>
              {/* <Link to="/private-tutoring">
                <button className="block mx-4 w-30 py-3 px-4 rounded-md shadow  bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link> */}
            </motion.div>
          </div>
        </div>
      </div>
      {/* END - Year 3 - 10 Math & English Tutoring */}
      {/* VCE Tutoring */}
      <div className="flex flex-col justify-center items-center md:flex-row ">
        {/* Left side */}
        <div className="mt-20 md:w-[50%]">
          <motion.img
            variants={fadeLeftToRight}
            initial={"hidden"}
            whileInView={fadeLeftToRight.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="mx-auto md:m-20 w-[70%] h-[80%]"
            src={vceTutoring}></motion.img>
        </div>
        {/* Right side */}
        <div className="md:w-[50%] flex flex-col justify-center items-center">
          <div className="w-[80%] flex flex-col gap-5">
            {/* <motion.h3
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-lg font-semibold tracking-wider text-purple uppercase">
              Tutoring
            </motion.h3> */}
            <motion.h1
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-5xl font-bold text-blue">
              <span className="pr-4">VCE / HSC / QCE</span>
              Tutoring
            </motion.h1>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-xl text-gray-500">
              Struggling to fully understand the content and keep up with the
              pace at school? Wanting to give yourself an edge by frontloading,
              staying ahead, and receiving comprehensive feedback that is
              personalised and highly targeted? Wondering what it takes to
              formulate winning responses in internal school assessments and
              external exams? Our top ATAR scorers and high-performing school
              teachers offer a suite of programs around your individual needs to
              help you achieve the ATAR you are aiming for.
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="flex">
              <Link to="/vce-hsc-qce-tutoring">
                <button className="block w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
      {/* END - VCE Tutoring */}
      {/* VCE Essay and Examination Marking Service */}
      <div className="flex flex-col-reverse md:flex-row justify-center items-center">
        {/* Left side */}
        <div className="md:w-[50%] flex flex-col items-center justify-center md:ml-20">
          <div className="w-[80%] md:w-[90%] flex flex-col gap-5">
            {/* <motion.h3
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-lg font-semibold tracking-wider text-purple uppercase">
              Feedback service
            </motion.h3> */}
            <motion.h1
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-5xl font-bold text-blue">
              <span className="pr-4">VCE / HSC / QCE</span>Essay and Examination
              Marking Service
            </motion.h1>
            <motion.p
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="text-xl text-gray-500">
              Practice does not make perfect unless you know exactly the steps
              you need to take to improve every time you complete a practice
              essay or examination. Our top ATAR scorers will provide you with
              specific, targeted and personalised feedback so you know what the
              next steps are to producing top essays and responses.
            </motion.p>
            <motion.div
              variants={fadeUp}
              initial={"hidden"}
              whileInView={fadeUp.visible}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="flex">
              <Link to="/vce-hsc-qce-essay-exam-marking">
                <button className="block w-30 py-3 px-4 rounded-md shadow bg-white text-purple border-2 border-purple font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link>
              {/* <Link to="/private-tutoring">
                <button className="block mx-4 w-30 py-3 px-4 rounded-md shadow  bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link> */}
            </motion.div>
          </div>
        </div>
        {/* END Left side */}
        {/* Right side */}
        <div className="md:w-[50%]">
          <motion.img
            variants={fadeRightToLeft}
            initial={"hidden"}
            whileInView={fadeRightToLeft.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="mx-auto mt-20 md:m-20 w-[70%] h-[80%]"
            src={essayMarking}></motion.img>
        </div>
        {/* END Right side */}
      </div>
      {/* END - VCE Essay and Examination Marking Service */}
      {/* VCE Study Guide */}
      <div className=" my-40 w-[90%] md:h-[32rem] border-2 border-purple bg-cta flex flex-col md:flex-row mx-auto rounded-3xl">
        {/* Left side */}
        <div className="md:w-[50%]">
          <motion.img
            variants={fadeLeftToRight}
            initial={"hidden"}
            whileInView={fadeLeftToRight.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="mt-10 mx-auto md:m-20 md:w-[70%] md:h-[80%]"
            src={vceStudyGuide}></motion.img>
        </div>
        {/* END Left side */}
        <div className="flex flex-col justify-center gap-5 md:w-[40%] m-10 md:m-0">
          {/* <motion.h3
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="text-lg font-semibold tracking-wider text-purple uppercase">
            Study Guides
          </motion.h3> */}
          <motion.h1
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="text-5xl font-bold text-blue">
            <span className="pr-2">VCE / HSC / QCE</span> Study Guides
          </motion.h1>
          <motion.p
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="text-xl text-gray-500">
            Why reinvent the wheel creating your own notes when our top ATAR
            scores have already done it for you? Save time by gaining full
            access to the notes and summaries produced by top ATAR scorers.
          </motion.p>
          <motion.div
            variants={fadeUp}
            initial={"hidden"}
            whileInView={fadeUp.visible}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="flex">
            <Link to="/vce-hsc-qce-study-guides">
              <button className="block w-30 py-3 px-4 rounded-md shadow bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
                Learn more
              </button>
            </Link>

            {/* <Link to="/private-tutoring">
                <button className="block mx-4 w-30 py-3 px-4 rounded-md shadow  bg-purple text-white border-2 border-white font-medium hover:bg-yellow">
                  Learn more
                </button>
              </Link> */}
          </motion.div>
        </div>
      </div>
      {/* END - VCE Study Guide */}
    </section>
  );
};

export default Services;
