import React, { useRef } from "react";
import { fadeUp, fadeLeftToRight, fadeRightToLeft } from "./";
import { motion, useScroll, useTransform } from "framer-motion";
import onlineFaceToFace from "./assets/onlineLearning/onlineFaceToFaceIllustration.svg";
import noGeographicLimitation from "./assets/onlineLearning/noGeographicLimitationIllustration.svg";
import qualityTutor from "./assets/onlineLearning/qualityTutoringIllustration.svg";
import oneToOnePrivateTutoring from "./assets/onlineLearning/oneToOnePrivateTutoringIllustration.svg";

const OnlineLearning = ({ customText }) => {
  const onlineLearningRef = useRef();
  const { scrollYProgress } = useScroll({
    target: onlineLearningRef,
    offset: ["start 20%", "end end"],
  });

  const opacity = {
    opacityOne: useTransform(scrollYProgress, [0, 0.25], [0, 1]),
    opacityTwo: useTransform(scrollYProgress, [0.25, 0.5], [0, 1]),
    opacityThree: useTransform(scrollYProgress, [0.5, 0.75], [0, 1]),
    opacityFour: useTransform(scrollYProgress, [0.75, 1], [0, 1]),
  };

  const scale = {
    scaleOneImage: useTransform(scrollYProgress, [0, 0.25], [4, 2]),
    scaleOneText: useTransform(scrollYProgress, [0, 0.25], [0, 1]),
    scaleTwoImage: useTransform(scrollYProgress, [0.25, 0.5], [4, 2]),
    scaleTwoText: useTransform(scrollYProgress, [0.25, 0.5], [0, 1]),
    scaleThreeImage: useTransform(scrollYProgress, [0.5, 0.75], [4, 2]),
    scaleThreeText: useTransform(scrollYProgress, [0.5, 0.75], [0, 1]),
    scaleFourImage: useTransform(scrollYProgress, [0.75, 1], [4, 2]),
    scaleFourText: useTransform(scrollYProgress, [0.75, 1], [0, 1]),
  };

  // const scaleOne = useTransform(scrollYProgress, [0, 0.24, 0.25], [0, 1, 0.5]);
  // const scaleTwo = useTransform(
  //   scrollYProgress,
  //   [0.25, 0.49, 0.5],
  //   [0, 1, 0.5]
  // );
  // const scaleThree = useTransform(
  //   scrollYProgress,
  //   [0.5, 0.74, 0.75],
  //   [0, 1, 0.5]
  // );
  // const scaleFour = useTransform(scrollYProgress, [0.75, 0.99, 1], [0, 1, 0.5]);
  // const pathLength = useTransform(scrollYProgress, [0, 0.2], [0, 1]);
  // const x = useTransform(scrollYProgress, [0, 0.25], [0, 700]);

  return (
    <section
      ref={onlineLearningRef}
      className="relative flex flex-col justify-center items-center mt-48 my-20">
      <motion.h2
        variants={fadeUp}
        initial={"hidden"}
        whileInView={fadeUp.visible}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="text-5xl font-bold text-blue w-[90%] text-center">
        What are the delivery modes for learning?
      </motion.h2>
      <div className="flex flex-col z-50 mt-20 gap-20">
        {/* Online Face to face */}
        <motion.div className="flex flex-col md:flex-row justify-center items-center gap-8">
          <motion.svg
            style={{ opacity: opacity.opacityOne }}
            className="hidden md:inline -rotate-90"
            width="189"
            height="74"
            viewBox="0 0 189 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.4108 65.2414C34.0376 64.6673 10.9561 42.9454 5.56535 36.8523C0.174642 30.7591 0.0421085 26.2917 4.71539 27.6683C9.38868 29.0449 35.4969 50.333 40.765 57.1612C46.0331 63.9894 44.784 65.8154 39.4108 65.2414Z"
              fill="#FCC219"
            />
            <path
              d="M61.69 53.6096C57.4174 50.3013 49.1687 19.698 47.7774 11.6823C46.3862 3.66669 48.6178 -0.205683 51.8735 3.41858C55.1291 7.04284 66.1819 38.8651 67.083 47.4422C67.9841 56.0192 65.9626 56.918 61.69 53.6096Z"
              fill="#FCC219"
            />
            <path
              d="M98.5343 50.7535C96.8578 45.6164 107.224 15.6641 110.576 8.25105C113.927 0.838043 117.949 -1.11212 118.607 3.71511C119.264 8.54234 110.533 41.0783 106.461 48.6804C102.388 56.2826 100.211 55.8907 98.5343 50.7535Z"
              fill="#FCC219"
            />
            <path
              d="M140.879 62.9746C141.693 57.6326 164.43 35.5498 170.759 30.4382C177.088 25.3266 181.557 25.3948 179.972 30.0016C178.387 34.6083 155.948 59.7342 148.89 64.6903C141.832 69.6465 140.064 68.3166 140.879 62.9746Z"
              fill="#FCC219"
            />
          </motion.svg>
          <motion.img
            // style={{ scale: scale.scaleOneImage }}
            className="w-80 h-80"
            src={onlineFaceToFace}
            alt="illustration of online and face to face tutoring"
          />
          <motion.p
            variants={fadeRightToLeft}
            initial={"hidden"}
            whileInView={fadeRightToLeft.visible}
            transition={{ duration: 1 }}
            className="text-gray-500 text-lg lg:mt-0 w-[70%] md:w-[30%] lg:ml-5">
            While most of our team members only conduct lessons{" "}
            <span className="font-bold">online</span>, a select few offer
            in-person tutoring at students’ homes and local libraries in:
            <span className="block text-purple my-2 font-normal mt-5">
              <span className=" font-bold">Brisbane:</span> Inner Suburbs,
              Southern Suburbs
            </span>
            <span className="block text-purple my-2 font-normal">
              <span className=" font-bold"> Gold Coast</span>
            </span>
            <span className="block text-purple my-2  font-normal">
              <span className=" font-bold">Melbourne:</span> Eastern Suburbs,
              South-Eastern Suburbs
            </span>
            <span className="block text-purple mt-2 mb-5  font-normal">
              <span className=" font-bold"> Sydney:</span> Inner
              South-West, Inner West, Lower North Shore, Western Suburbs
            </span>
            <span>
              We <span className="font-bold">strongly</span> encourage you to
              opt {customText} for online tutoring.
            </span>
          </motion.p>
        </motion.div>
        {/* No Geographic limitation */}
        <motion.div className="mt-20 flex flex-col-reverse md:flex-row justify-center items-center gap-10">
          <motion.p
            variants={fadeLeftToRight}
            initial={"hidden"}
            whileInView={fadeLeftToRight.visible}
            transition={{ duration: 1 }}
            className="text-gray-500 text-lg lg:mt-0 w-[70%] md:w-[30%] lg:mr-5">
            The elimination of geographical barriers is among the key advantages
            of online learning. This means when we set{" "}
            {customText ? customText : "you"} up for private tutoring, we have a
            far greater choice of tutors to choose from that will best match{" "}
            {customText ? customText + "'s" : "you"} individual needs, learning
            styles and personality.
          </motion.p>
          <img
            // style={{ scale: scale.scaleTwoImage }}
            className="w-80 h-80"
            src={noGeographicLimitation}
            alt="illustration of not limited by geographical area"
          />
          <motion.svg
            style={{ opacity: opacity.opacityTwo }}
            className="hidden md:inline rotate-90"
            width="189"
            height="74"
            viewBox="0 0 189 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.4108 65.2414C34.0376 64.6673 10.9561 42.9454 5.56535 36.8523C0.174642 30.7591 0.0421085 26.2917 4.71539 27.6683C9.38868 29.0449 35.4969 50.333 40.765 57.1612C46.0331 63.9894 44.784 65.8154 39.4108 65.2414Z"
              fill="#0B4697"
            />
            <path
              d="M61.69 53.6096C57.4174 50.3013 49.1687 19.698 47.7774 11.6823C46.3862 3.66669 48.6178 -0.205683 51.8735 3.41858C55.1291 7.04284 66.1819 38.8651 67.083 47.4422C67.9841 56.0192 65.9626 56.918 61.69 53.6096Z"
              fill="#0B4697"
            />
            <path
              d="M98.5343 50.7535C96.8578 45.6164 107.224 15.6641 110.576 8.25105C113.927 0.838043 117.949 -1.11212 118.607 3.71511C119.264 8.54234 110.533 41.0783 106.461 48.6804C102.388 56.2826 100.211 55.8907 98.5343 50.7535Z"
              fill="#0B4697"
            />
            <path
              d="M140.879 62.9746C141.693 57.6326 164.43 35.5498 170.759 30.4382C177.088 25.3266 181.557 25.3948 179.972 30.0016C178.387 34.6083 155.948 59.7342 148.89 64.6903C141.832 69.6465 140.064 68.3166 140.879 62.9746Z"
              fill="#0B4697"
            />
          </motion.svg>
        </motion.div>
        {/* Quality tutor */}
        <motion.div className="mt-20 flex flex-col md:flex-row justify-center items-center gap-10">
          <motion.svg
            style={{ opacity: opacity.opacityThree }}
            className="hidden md:inline -rotate-90"
            width="189"
            height="74"
            viewBox="0 0 189 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.4108 65.2414C34.0376 64.6673 10.9561 42.9454 5.56535 36.8523C0.174642 30.7591 0.0421085 26.2917 4.71539 27.6683C9.38868 29.0449 35.4969 50.333 40.765 57.1612C46.0331 63.9894 44.784 65.8154 39.4108 65.2414Z"
              fill="#863d90"
            />
            <path
              d="M61.69 53.6096C57.4174 50.3013 49.1687 19.698 47.7774 11.6823C46.3862 3.66669 48.6178 -0.205683 51.8735 3.41858C55.1291 7.04284 66.1819 38.8651 67.083 47.4422C67.9841 56.0192 65.9626 56.918 61.69 53.6096Z"
              fill="#863d90"
            />
            <path
              d="M98.5343 50.7535C96.8578 45.6164 107.224 15.6641 110.576 8.25105C113.927 0.838043 117.949 -1.11212 118.607 3.71511C119.264 8.54234 110.533 41.0783 106.461 48.6804C102.388 56.2826 100.211 55.8907 98.5343 50.7535Z"
              fill="#863d90"
            />
            <path
              d="M140.879 62.9746C141.693 57.6326 164.43 35.5498 170.759 30.4382C177.088 25.3266 181.557 25.3948 179.972 30.0016C178.387 34.6083 155.948 59.7342 148.89 64.6903C141.832 69.6465 140.064 68.3166 140.879 62.9746Z"
              fill="#863d90"
            />
          </motion.svg>
          <img
            // style={{ scale: scale.scaleThreeImage }}
            className="w-80 h-80"
            src={qualityTutor}
            alt="illustration of quality tutoring offered"
          />
          <motion.p
            variants={fadeRightToLeft}
            initial={"hidden"}
            whileInView={fadeRightToLeft.visible}
            transition={{ duration: 1 }}
            className="text-gray-500 text-center text-lg mt-10 lg:mt-0 w-[70%] md:w-[30%] lg:ml-5">
            The quality of any learning experiences, whether it be in-person or
            online, is highly dependent on the calibre of the tutor. When online
            tutoring is delivered effectively, lessons can be as or even more
            impactful than in-person tutoring. Our team members possess high
            degrees of technology savviness to ensure the online lessons are of
            the highest quality that fully engages{" "}
            {customText ? customText : "you"}.
          </motion.p>
        </motion.div>
        {/* One to one private tutoring */}
        <motion.div className="mt-20 flex flex-col-reverse md:flex-row justify-center items-center gap-10">
          <motion.p
            variants={fadeLeftToRight}
            initial={"hidden"}
            whileInView={fadeLeftToRight.visible}
            transition={{ duration: 1 }}
            className="text-gray-500 text-lg text-center mt-10 lg:mt-0 w-[70%] md:w-[30%] lg:mr-5">
            Given the nature of one-to-one and small group tutoring, the level
            of individualised attention {customText ? customText : "you"} will
            receive from your tutor online will not be any less than if the
            lesson is conducted in-person.
          </motion.p>
          <img
            // style={{ scale: scale.scaleFourImage }}
            className="w-80 h-80"
            src={oneToOnePrivateTutoring}
            alt="illustration of one to one private tutoring"
          />
          <motion.svg
            style={{ opacity: opacity.opacityFour }}
            className="hidden md:inline rotate-90"
            width="189"
            height="74"
            viewBox="0 0 189 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M39.4108 65.2414C34.0376 64.6673 10.9561 42.9454 5.56535 36.8523C0.174642 30.7591 0.0421085 26.2917 4.71539 27.6683C9.38868 29.0449 35.4969 50.333 40.765 57.1612C46.0331 63.9894 44.784 65.8154 39.4108 65.2414Z"
              fill="#FF914D"
            />
            <path
              d="M61.69 53.6096C57.4174 50.3013 49.1687 19.698 47.7774 11.6823C46.3862 3.66669 48.6178 -0.205683 51.8735 3.41858C55.1291 7.04284 66.1819 38.8651 67.083 47.4422C67.9841 56.0192 65.9626 56.918 61.69 53.6096Z"
              fill="#FF914D"
            />
            <path
              d="M98.5343 50.7535C96.8578 45.6164 107.224 15.6641 110.576 8.25105C113.927 0.838043 117.949 -1.11212 118.607 3.71511C119.264 8.54234 110.533 41.0783 106.461 48.6804C102.388 56.2826 100.211 55.8907 98.5343 50.7535Z"
              fill="#FF914D"
            />
            <path
              d="M140.879 62.9746C141.693 57.6326 164.43 35.5498 170.759 30.4382C177.088 25.3266 181.557 25.3948 179.972 30.0016C178.387 34.6083 155.948 59.7342 148.89 64.6903C141.832 69.6465 140.064 68.3166 140.879 62.9746Z"
              fill="#FF914D"
            />
          </motion.svg>
        </motion.div>
      </div>
    </section>
  );
};

export default OnlineLearning;
